
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useRoute } from "vue-router";
import { Modal } from "bootstrap";
import Item1Vue from "@/layout/header/partials/activity-timeline/Item1.vue";
moment.locale("id");

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      uploadsk: "",
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      // end loading overlay setting
      showModalSk: false,
      showModalAll: false,
      showModalPks: false,
      showModalOprasi: false,
      extModalSk: "",
      extModalAll: "",
      extModalPks: "",
      extModalOprasi: "",
      targetDataPks: "",
      targetDataSk: "",
      targetDataOperasional: "",
      schoolName: "",
      tableData: [
        {
          name: "",
          _id: "",
          documentLegality: {
            number: "",
            startDate: "",
            expiredDate: "",
            status: "",
            responsiblePerson: null,
            softFile: false,
            hardCopy: {
              detail: [
                {
                  createTime: "",
                  creatorId: "",
                  status: "",
                  tanggal: "",
                  editable: true,
                  id: "",
                },
              ],
              note: null,
            },
            note: "",
            documentUrl: "",
            documentSchoolOperational: "",
            documentSkPengurus: "",
            typ: "",
            auto_renewal: false,
            renewal_in_days: 0,
            createTime: "",
            creatorId: "",
            updateTime: "",
            salesAreaName: "",
            editorId: "",
            legalCreatorName: "",
            id: "",
            documentSchoolOperationalUploadDate: null,
            documentSkPengurusUploadDate: null,
          },
          listBankName: null,
          schoolEmail: null,
          schoolPhone: null,
          topupFee: 0,
          salesAreaName: "",
          isPksActive: false,
          uploadDate: "",
        },
      ],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      startDate: "",
      role: "",
      id: "",
      schoolId: "",
      dokumensk: "",
    };
  },
  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);
    const route = useRoute();
    this.id = "" + route.params._id;
    this.getId();
    this.getschool();
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getschool() {
      const route = useRoute();
      let cek = "" + route.params.id;
      ApiService.getWithoutSlug("crmv2/main_school/school/school/" + this.id)
        .then((res) => {
          console.log(res.data.name);
          this.schoolName = res.data.name;
        })
        .catch((e) => {});
    },
    getId() {
      this.isLoading = true;
      const route = useRoute();
      this.tableData = [];
      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/get_last_document_legal/" + this.id
      )
        .then(({ data }) => {
          this.tableData.push(data);
          console.log(this.tableData);
          this.isLoading = false;
        })
        .catch((response) => {
          console.log("eror");
          this.isLoading = false;
        });
    },

    uploadskfungtion() {
      this.targetDataSk = "";
    },

    klikreset() {
      (this.targetDataPks = ""),
        (this.targetDataSk = ""),
        (this.targetDataOperasional = "");
      this.showModalSk = false;
      this.showModalAll = false;
      this.showModalPks = false;
      this.showModalOprasi = false;
    },

    handleBlobResponse2(title, blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title + " " + "." + extension);
      document.body.appendChild(link);
      link.click();
    },

    downloadPks(id) {
      this.isLoading = true;
      console.log(extension);
      const toast = useToast();
      var extension = this.getExtensionFile(
        this.tableData[0].documentLegality.documentUrl
      );
      // console.log(extension,"asdasdasd")
      var date = moment().format("YYYY-MM-DD h:mm:ss ");
      console.log(date);

      ApiService.getDownload(
        `crmv2/main_legal/legal/legal/download_document_legality/${this.tableData[0]._id}/${this.tableData[0].documentLegality.id}?time=${date}`
      )
        .then((response) => {
          this.handleBlobResponse2(
            "Dokumen PKS" + " " + this.schoolName,
            response.data,
            extension
          );
          // this.getExtensionFromDetailLegal('docsk',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse2(response.data, item, this.getExtensionFromDetail), 1000)});;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },

    downloadOperational(id) {
      this.isLoading = true;
      const toast = useToast();
      // var extension = this.getExtensionFile(
      //   this.tableData[0].documentLegality.documentSchoolOperational
      // );
      var extension =
        this.tableData[0].documentLegality.documentSchoolOperational.split(".");
      var date = moment().format("YYYY-MM-DD h:mm:ss ");

      ApiService.getDownload(
        `crmv2/main_legal/legal/legal/download_document_operational_school/${this.tableData[0]._id}/${extension[0]}?time=${date}`
      )
        .then((response) => {
          this.handleBlobResponse2(
            "Dokumen Operasional" + " " + this.schoolName,
            response.data,
            extension[1]
          );
          // this.getExtensionFromDetailLegal('docsk',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse2(response.data, item, this.getExtensionFromDetail), 1000)});;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },
    dateTimeFull(val) {
      if (val) {
        let text = val;
        let result = text.includes("T");
        let jam = "";
        if (result == true) {
          // let text = "2022-04-05T09:45:32.719896";
          let val = text.split("T");
          let subst = val[1].substr(0, 8);
          jam = subst;
        } else {
          // jam = "hh:mm";
        }
        return moment(val).format("YYYY-MM-DD " + jam);
      } else {
        return "-";
      }
    },
    changeBahasa(val) {
      // console.log(val)
      switch (val) {
        case "Waiting":
          return "Menunggu";
          break;
        case "Process":
          return "Proses";
          break;
        case "Reject":
          return "Ditolak";
          break;
        case "Cancel":
          return "Batal";
          break;
        case "Approve":
          return "Disetujui";
          break;
      }
    },

    downloadSk(id) {
      this.isLoading = true;
      const toast = useToast();
      const $route = useRoute();
      // console.log(
      //   this.getExtensionFile(this.tableData[0].documentLegality.documentUrl)
      // );
      var extension =
        this.tableData[0].documentLegality.documentSkPengurus.split(".");

      var date = moment().format("YYYY-MM-DD h:mm:ss ");
      console.log(extension[0], "cek");

      ApiService.getDownload(
        `crmv2/main_legal/legal/legal/download_document_sk_school/${this.tableData[0]._id}/${extension[0]}?time=${date}`
      )
        .then((response) => {
          this.handleBlobResponse2(
            "Dokumen SK Pengurus" + " " + this.schoolName,
            response.data,
            extension[1]
          );
          // this.getExtensionFromDetailLegal('docsk',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse2(response.data, item, this.getExtensionFromDetail), 1000)});;
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
          this.isLoading = false;
        });
    },
    downloadItem2() {
      const toast = useToast();
      var extension = "docx";

      ApiService.getDownload(
        "crmv2/main_legal/draft_legal/legal/download_draft_template?documentType=PKS"
      )
        .then((response) => {
          this.handleBlobResponse2(
            "Download Draft Sekolah" + " " + this.schoolName,
            response.data,
            extension
          );
          // this.getExtensionFromDetailLegal('docsk',item._id,item.documentId)
          // let promise = new Promise((resolve, reject) => {setTimeout(() => this.handleBlobResponse2(response.data, item, this.getExtensionFromDetail), 1000)});;
          toast.success("Sukses Unduh File");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File Tidak Ada");
        });
    },

    // ================================> UPLOAD FUNCTION <============================== \\
    handleFileUploadPks(event) {
      const toast = useToast();
      console.log(event.target.files[0].name);
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        console.log(myArray[1]);
        if (
          myArray[1] == "docx" ||
          myArray[1] == "pdf" ||
          myArray[1] == "doc"
        ) {
          this.targetDataPks = event.target.files[0];
        } else {
          return toast.error(" File Hanya .doc , .docx dan .pdf");
        }
      } else {
        return toast.error("File Kosong");
      }
    },

    handleFileUploadSk(event) {
      const toast = useToast();
      console.log(event.target.files[0].name);
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        console.log(myArray[1]);
        if (
          myArray[1] == "docx" ||
          myArray[1] == "pdf" ||
          myArray[1] == "doc"
        ) {
          this.targetDataSk = event.target.files[0];
        } else {
          return toast.error(" File Hanya .doc , .docx dan .pdf");
        }
      } else {
        return toast.error("File Kosong");
      }
    },

    handleFileUploadOperasional(event) {
      const toast = useToast();
      console.log(event.target.files[0].name);
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        console.log(myArray[1]);
        if (
          myArray[1] == "docx" ||
          myArray[1] == "pdf" ||
          myArray[1] == "doc"
        ) {
          this.targetDataOperasional = event.target.files[0];
        } else {
          return toast.error(" File Hanya .doc , .docx dan .pdf");
        }
      } else {
        return toast.error("File Kosong");
      }
    },
    simpanAll() {
      this.isLoading = false;
      const toast = useToast();
      // console.log(this.targetDataPks)
      // if (
      //   this.targetDataPks == "" &&
      //   this.targetDataOperasional !== "" &&
      //   this.targetDataSk !== ""
      // ) {
      //   return toast.error("Dokumen PKS Wajib Diisi");
      // }
      // if (
      //   this.targetDataPks !== "" &&
      //   this.targetDataOperasional == "" &&
      //   this.targetDataSk !== ""
      // ) {
      //   return toast.error("File Data Operasional Kosong");
      // }
      // if (
      //   this.targetDataPks !== "" &&
      //   this.targetDataOperasional !== "" &&
      //   this.targetDataSk == ""
      // ) {
      //   return toast.error("File Data Pengurus Kosong") ||
      //    toast.error("File Data Operasional Kosong");
      // }
      if (
        this.targetDataPks == "" &&
        this.targetDataOperasional !== "" &&
        this.targetDataSk !== ""
      ) {
        return toast.error("PKS Wajib Diisi");
      }
      if (
        this.targetDataPks !== "" &&
        this.targetDataOperasional == "" &&
        this.targetDataSk !== ""
      ) {
        return toast.error("Operasional Wajib Diisi");
      }
      if (
        this.targetDataPks !== "" &&
        this.targetDataOperasional !== "" &&
        this.targetDataSk == ""
      ) {
        return toast.error("SK Pengurus Wajib Diisi");
      }

      //TOAST UNTUK SATU FILE YG HANYA SATU DIISI
      if (
        this.targetDataPks == "" &&
        this.targetDataOperasional !== "" &&
        this.targetDataSk == ""
      ) {
        return (
          toast.error("PKS Wajib Diisi"), toast.error("SK Pengurus Wajib Diisi")
        );
      }
      if (
        this.targetDataPks !== "" &&
        this.targetDataOperasional == "" &&
        this.targetDataSk == ""
      ) {
        return (
          toast.error("Operasional Wajib Diisi"),
          toast.error("SK Pengurus Wajib Diisi")
        );
      }
      if (
        this.targetDataPks == "" &&
        this.targetDataOperasional == "" &&
        this.targetDataSk !== ""
      ) {
        return (
          toast.error("PKS Wajib Diisi"), toast.error("Operasional Wajib Diisi")
        );
      }

      if (
        this.targetDataPks == "" &&
        this.targetDataOperasional == "" &&
        this.targetDataSk == ""
      ) {
        return (
          toast.error("PKS Wajib Diisi"),
          toast.error("Operasional Wajib Diisi"),
          toast.error("SK Pengurus Wajib Diisi")
        );
      }
      // if (this.targetDataPks == "") {
      //   return toast.error("Dokumen PKS Wajib Diisi");
      // }

      let formData = new FormData();
      formData.append("documentFile", this.targetDataPks);
      formData.append("documentSchoolOperational", this.targetDataOperasional);
      formData.append("documentSkPengurus", this.targetDataSk);
      ApiService.postWithData(
        `crmv2/main_legal/legal/legal/create_and_upload_document_legality/${this.id}?documentType=PKS`,
        formData
      )
        .then((res) => {
          console.log(res.data, "data");
          this.getId();
          toast.success("Berhasil Upload FIle");
          this.isLoading = false;
          this.klikreset();
          // window.location.reload();
        })
        .catch((e) => {
          this.getId();
          console.log(e.data.detail, "error");
          toast.error(e.response.data.detail);
          this.isLoading = false;
          this.klikreset();

          // toast.error("Silahkan Upload FIle .doc atau .docx");
        });
    },
    simpanOnlyPks(e) {
      console.log(e)
      const toast = useToast();
      if (!e) {
        return toast.error("File belum inputkan")
      }
      let formData = new FormData();
      if(!this.targetDataPks){
        return toast.error("Data Masih Kosong")
      }
      formData.append("documentFile", this.targetDataPks);
      console.log(this.targetDataPks, "sdude")
      ApiService.postWithData(
        `crmv2/main_legal/legal/legal/${this.id}/${e}`,
        formData
      )
        .then((res) => {
          this.getId();
          console.log(res.data, "data");
          toast.success("Berhasil Mengupdate Data");
          // location.reload();
          this.showModalPks = false;
        })
        .catch((e) => {
          this.getId();
          this.showModalPks = false;

          // toast.error(e.response.data.detail);
          toast.error(e.response.data.detail);
          // var modal = new Modal(document.getElementById("kt_modal_sk"));
          // modal.hide();
        });
    },
    simpanOnlySk(e) {
      const toast = useToast();
      let formData = new FormData();
      if(!this.targetDataSk){
        return toast.error("Data Masih Kosong")
      }
      formData.append("documentSkFile", this.targetDataSk);
      ApiService.postWithData(
        `crmv2/main_legal/legal/legal/reupload_document_sk_school/${this.id}/${e}`,
        formData
      )
        .then((res) => {
          this.getId();
          console.log(res.data, "data");
          toast.success("Berhasil reupload dokumen SK Pengurus");
          // var modal = new Modal(document.getElementById("kt_modal_sk"));
          // modal.hide();
          this.showModalSk = false;
        })
        .catch((e) => {
          this.getId();
          console.log(e);
          // toast.error(e.response.data.detail);
          toast.error(e.response.data.detail);
          this.showModalSk = false;
        });
    },
    simpanOnlyOprs(e) {
      const toast = useToast();
      let formData = new FormData();
      if(!this.targetDataOperasional){
        return toast.error("Data Masih Kosong")
      }
      formData.append("documentOpsFile", this.targetDataOperasional);
      ApiService.postWithData(
        `crmv2/main_legal/legal/legal/reupload_document_operational_school/${this.id}/${e}`,
        formData
      )
        .then((res) => {
          this.getId();
          console.log(formData, "data");
          toast.success("Berhasil reupload dokumen Operasional");

          // modal.hide();
          this.showModalOprasi = false;
        })
        .catch((e) => {
          this.getId();
          this.showModalOprasi = false;

          console.log(e);
          // toast.error("Silahkan Upload FIle .doc atau .docx");
          toast.error(e.response.data.detail);
        });
    },
    getExtensionFile(val) {
      if (val) {
        const myArray = val.split(".");
        // console.log(myArray, "cekkk");
        return myArray[1];
      } else {
        return "-";
      }
    },
  },
});
