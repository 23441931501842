
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface data {
  tableDataFollowUp: any;
}

interface tambahFollowUpA {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  note: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  errorprocessed: string;
  erroractorName: string;
  aksi: string;
  idProgress: string;
}

export default defineComponent({
  name: "Progress Follow Up Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0;

    const data = reactive<data>({
      tableDataFollowUp: [],
    });

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      errorprocessed: "",
      erroractorName: "",
      aksi: "",
      idProgress: "",
    });

    const tambahFollowUp = reactive<tambahFollowUpA>({
      progressType: "Follow%20Up",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const getProgress = () => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          data.tableDataFollowUp = res.data.progress.followUp.detail;
          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const submitFollowUp = () => {

      if (
        tambahFollowUp.progressDate === "" ||
        tambahFollowUp.processed === "" ||
        tambahFollowUp.actorName === "" 
      ) {
        items.errorprogressDate = "Tanggal Wajib Diisi";
        items.errorprocessed = "Proses Wajib Diisi";
        items.erroractorName = "Penanggung Jawab Wajib Diisi";
      } else {
        items.errorprogressDate = "";
        items.errorprocessed = "";
        items.erroractorName = "";

        // jika tombol tambah
        if (items.aksi == "tambah") {

          isLoading.value = true;
          // Activate loading indicator
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/progress/" +
              route.params._id +
              "?progressType=" +
              tambahFollowUp.progressType +
              "&actorName=" +
              tambahFollowUp.actorName +
              "&progressDate=" +
              tambahFollowUp.progressDate +
              "&processed=" +
              tambahFollowUp.processed +
              "&note=" +
              tambahFollowUp.note 
          )

            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Tambah Follow Up");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahFollowUp.progressDate = ""; 
              tambahFollowUp.processed = "";
              tambahFollowUp.actorName = "";
              tambahFollowUp.note = "";

              isLoading.value = false;
              
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });
        } else {

          isLoading.value = true;

          // jika tombol edit progress
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/update_presentation_detail/" +
              route.params._id +
              "/" +
              items.idProgress +
              "?progressDate=" +
              tambahFollowUp.progressDate +
              "&processed=" +
              tambahFollowUp.processed +
              "&note=" +
              tambahFollowUp.note +
              "&actorName=" +
              tambahFollowUp.actorName
          )
            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Update Follow Up");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahFollowUp.progressDate = ""; 
              tambahFollowUp.processed = "";
              tambahFollowUp.actorName = "";
              tambahFollowUp.note = "";
              
              isLoading.value = false;
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });

        }

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const getId = (index, item) => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_presentation_webinar_detail/" + route.params._id +"/" + item.id
      )
        .then((res) => {
          tambahFollowUp.progressDate = res.data.progressDate; 
          tambahFollowUp.processed = res.data.processed;
          tambahFollowUp.actorName = res.data.actorName;
          tambahFollowUp.note = res.data.note;

          items.idProgress = res.data.progressId;
          items.aksi = "ubah";

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);

          isLoading.value = false;
        });
    };

    const tambahV = () => {
      items.aksi = "tambah";
    };

    const handleDelete = (index, item) => {
      Swal
        .fire({
          title: "Apakah Anda Yakin Hapus Follow Up ?",
          text: `Data yang telah dihapus tidak dapat dikembalikan !`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Ya, hapus !",
          cancelButtonText: "Batal",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            deleteRow(item);
          }
        });
    };

    const deleteRow = (item) => {

      isLoading.value = true;
      const toast = useToast();

      ApiService
        .delete('crmv2/main_school/school/school/delete_presentation_detail/' + route.params._id + '/' + item.id +'?progress=Follow%20Up')
        .then((res) => {
          toast.success("Hapus Progress Follow Up Berhasil");
          getProgress();

          isLoading.value = false;
         })

        .catch((error) => {
          toast.error("Gagal Hapus Follow Up");
          isLoading.value = false;
        });
    };

    const kosongkanVariabel = () => {

      // kosongkan variabel
      tambahFollowUp.progressDate = ""; 
      tambahFollowUp.processed = "";
      tambahFollowUp.actorName = "";
      tambahFollowUp.note = "";

      // kosongkan variable error message
      items.errorprogressDate = "";
      items.errorprocessed = "";
      items.erroractorName = "";

    };

    onMounted(() => {
      getProgress();
    });

    return {
      submitButton,
      data,
      getProgress,
      submitFollowUp,
      items,
      tambahFollowUp,
      uploadModalRef,
      handleDelete,
      deleteRow,
      getId,
      kosongkanVariabel,
      tambahV,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

    };
  },
});
