
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface data {
  tableDataWebinar: any;
}

interface tambahWebinarA {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  respon: string;
  participant: string;
  note: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  errorprocessed: string;
  erroractorName: string;
  errorrespon: string;
  errorparticipant: string;
  aksi: string;
  idProgress: string;
}

export default defineComponent({
  name: "Progress Webinar Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    const data = reactive<data>({
      tableDataWebinar: [],
    });

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      errorprocessed: "",
      erroractorName: "",
      errorrespon: "",
      errorparticipant: "",
      aksi: "",
      idProgress: "",
    });

    const tambahWebinar = reactive<tambahWebinarA>({
      progressType: "Webinar",
      progressDate: "",
      processed: "",
      actorName: "",
      respon: "",
      participant: "",
      note: "",
    });

    const getProgress = () => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          data.tableDataWebinar = res.data.progress.webinar.detail;

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);

          isLoading.value = false;
        });
    };

    const submitAdd = () => {
      if (
        tambahWebinar.progressDate === "" ||
        tambahWebinar.processed === "" ||
        tambahWebinar.actorName === "" ||
        tambahWebinar.respon === "" ||
        tambahWebinar.participant === ""
      ) {
        items.errorprogressDate = "Tanggal Wajib Diisi";
        items.errorprocessed = "Proses Wajib Diisi";
        items.erroractorName = "Pengisi Webinar Wajib Diisi";
        items.errorrespon = "Respon Wajib Diisi";
        items.errorparticipant = "Peserta Wajib Diisi";
      } else {
        items.errorprogressDate = "";
        items.errorprocessed = "";
        items.erroractorName = "";
        items.errorrespon = "";
        items.errorparticipant = "";

        // jika tombol tambah
        if (items.aksi == "tambah") {

          isLoading.value = true;

          // Activate loading indicator
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/progress/" +
              route.params._id +
              "?progressType=" +
              tambahWebinar.progressType +
              "&actorName=" +
              tambahWebinar.actorName +
              "&progressDate=" +
              tambahWebinar.progressDate +
              "&processed=" +
              tambahWebinar.processed +
              "&participant=" +
              tambahWebinar.participant +
              "&note=" +
              tambahWebinar.note +
              "&respon=" +
              tambahWebinar.respon
          )

            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Tambah Webinar");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahWebinar.progressDate = ""; 
              tambahWebinar.processed = "";
              tambahWebinar.actorName = "";
              tambahWebinar.respon = "";
              tambahWebinar.participant = "";
              tambahWebinar.note = "";

              isLoading.value = false;
              
            })
            .catch((e) => {
              if (e.response.status == 401) {
                toast.error(e.response.data.detail);
                router.push('/sign-in');
                isLoading.value = false;
              } else {
                items.disableButton = false;
                hideModal(uploadModalRef.value);
                toast.error(e.response.data.detail);
                isLoading.value = false;
              }
            });
        } else {

          isLoading.value = true;

          // jika tombol edit progress
          
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/update_presentation_detail/" +
              route.params._id +
              "/" +
              items.idProgress +
              "?progressDate=" +
              tambahWebinar.progressDate +
              "&processed=" +
              tambahWebinar.processed +
              "&note=" +
              tambahWebinar.note +
              "&actorName=" +
              tambahWebinar.actorName +
              "&respon=" +
              tambahWebinar.respon +
              "&participant=" +
              tambahWebinar.participant
          )
            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Update Webinar");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahWebinar.progressDate = ""; 
              tambahWebinar.processed = "";
              tambahWebinar.actorName = "";
              tambahWebinar.respon = "";
              tambahWebinar.participant = "";
              tambahWebinar.note = "";

              isLoading.value = false;
              
            })
            .catch((e) => {
              if (e.response.status == 401) {
                toast.error(e.response.data.detail);
                router.push('/sign-in');
                isLoading.value = false;
              } else {
                items.disableButton = false;
                hideModal(uploadModalRef.value);
                toast.error(e.response.data.detail);
                isLoading.value = false;
              }
            });

        }

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const getId = (index, item) => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_presentation_webinar_detail/" + route.params._id +"/" + item.id
      )
        .then((res) => {
          tambahWebinar.progressDate = res.data.progressDate; 
          tambahWebinar.processed = res.data.processed;
          tambahWebinar.actorName = res.data.actorName;
          tambahWebinar.respon = res.data.respon;
          tambahWebinar.participant = res.data.participant;
          tambahWebinar.note = res.data.note;

          items.idProgress = res.data.progressId;
          items.aksi = "ubah";

          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          }
        });
    };

    const tambahV = () => {
      items.aksi = "tambah";
    };

    const handleDelete = (index, item) => {
      Swal
        .fire({
          title: "Apakah Anda Yakin Hapus Webinar ?",
          text: `Data yang telah dihapus tidak dapat dikembalikan !`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Ya, hapus !",
          cancelButtonText: "Batal",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            deleteRow(item);
          }
        });
    };

    const deleteRow = (item) => {

      isLoading.value = true;

      const toast = useToast();

      ApiService
        .delete('crmv2/main_school/school/school/delete_presentation_detail/' + route.params._id + '/' + item.id +'?progress=Webinar')
        .then((res) => {
          toast.success("Hapus Progress Webinar Berhasil");
          getProgress();

          isLoading.value = false;
            
         })

        .catch((error) => {

          toast.error("Gagal Hapus Webinar");

          isLoading.value = false;

        });
    };

    const kosongkanVariabel = () => {

      // kosongkan variabel
      tambahWebinar.progressDate = ""; 
      tambahWebinar.processed = "";
      tambahWebinar.actorName = "";
      tambahWebinar.respon = "";
      tambahWebinar.participant = "";
      tambahWebinar.note = "";

      // kosongkan variable error message
      items.errorprogressDate = "";
      items.errorprocessed = "";
      items.erroractorName = "";
      items.errorrespon = "";
      items.errorparticipant = "";

    };

    onMounted(() => {
      getProgress();
    });

    return {
      submitButton,
      data,
      getProgress,
      submitAdd,
      items,
      tambahWebinar,
      uploadModalRef,
      handleDelete,
      deleteRow,
      getId,
      kosongkanVariabel,
      tambahV,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

    };
  },
});
