
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import moment, { invalid } from "moment";
moment.locale("id");

interface editSekolah {
  selfMapping: boolean;
  startMappingDate: string;
  endMappingDate: string;
  limitCard: string;
}

interface editSekolah2 {
  selfMapping: boolean;
  startMappingDate: string;
  endMappingDate: string;
  limitCard: string;
}

interface editSekolah3 {
  selfMapping: boolean;
  startMappingDate: string;
  endMappingDate: string;
  limitCard: string;
}

interface items {
  startMappingDate: string;
  endMappingDate: string;
  limitCard: string;
  selfMapping: boolean;
  url: any;
  role: any;
  disableButton: boolean;

  errorstartMappingDate: string;
  errorendMappingDate: string;
  errorlimitCard: number;
}

export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      startMappingDate: "",
      endMappingDate: "",
      disableButton: false,
      limitCard: "",
      selfMapping: false,
      url: "",
      role: "",

      errorstartMappingDate: "",
      errorendMappingDate: "",
      errorlimitCard: 0,
    });

    const editValidator = Yup.object().shape({
      startMappingDate: Yup.string().required(
        "Tanggal Start Mapping Date Wajib Diisi "
      ),
      endMappingDate: Yup.string().required(
        "Tanggal End Mapping Date Wajib Diisi"
      ),
      limitCard: Yup.string().required("Limit Card Wajib Diisi"),
    });

    const editSekolah = reactive<editSekolah>({
      selfMapping: false,
      startMappingDate: "",
      endMappingDate: "",
      limitCard: "",
    });

    const oncheck = (e) => {
      console.log(editSekolah.selfMapping);
      if (e == true) {
        editSekolah.endMappingDate = editSekolah3.endMappingDate;
        editSekolah.startMappingDate = editSekolah3.startMappingDate;
        editSekolah.limitCard = editSekolah3.limitCard;
      } else {
        editSekolah.endMappingDate = editSekolah3.endMappingDate;
        editSekolah.startMappingDate = editSekolah3.startMappingDate;
        editSekolah.limitCard = editSekolah3.limitCard;
      }
    };
    const editSekolah2 = reactive<editSekolah2>({
      startMappingDate: "",
      endMappingDate: "",
      limitCard: "",
      selfMapping: false,
    });
    const editSekolah3 = reactive<editSekolah3>({
      startMappingDate: "",
      endMappingDate: "",
      limitCard: "",
      selfMapping: false,
    });

    const check = (e) => {
      console.log(e.target.value);
    };

    const getdata = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/configuration/card/" + route.params._id
      )
        .then((res) => {
          console.log(
            moment(res.data.startMappingDate).format("YYYY-MM-DDTHH:mm")
          );
          editSekolah.selfMapping = res.data.selfMapping;
          if (editSekolah.selfMapping == false) {
            editSekolah.startMappingDate = editSekolah2.startMappingDate;
            editSekolah.endMappingDate = editSekolah2.endMappingDate;
            editSekolah.limitCard = editSekolah2.limitCard;
            editSekolah3.startMappingDate = moment(
              res.data.startMappingDate
            ).format("YYYY-MM-DDTHH:mm");
            editSekolah3.endMappingDate = moment(
              res.data.endMappingDate
            ).format("YYYY-MM-DDTHH:mm");
            editSekolah3.limitCard = res.data.limitCard;
          } else {
            editSekolah.startMappingDate = moment(
              res.data.startMappingDate
            ).format("YYYY-MM-DDTHH:mm");
            editSekolah.endMappingDate = moment(res.data.endMappingDate).format(
              "YYYY-MM-DDTHH:mm"
            );
            editSekolah.limitCard = res.data.limitCard;
            editSekolah3.endMappingDate = editSekolah.endMappingDate;
            editSekolah3.startMappingDate = editSekolah.startMappingDate;
            editSekolah3.limitCard = editSekolah.limitCard;
          }
          console.log(editSekolah.startMappingDate);
        })

        .catch((e) => {
          isLoading.value = false;
        });
    };
    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      getdata();
    });

    const simpan = () => {
      if (editSekolah.startMappingDate == "Invalid date") {
        console.log("cek");
        return toast.error("Tanggal Start Mapping Date Wajib Diisi");
      }
      if (editSekolah.endMappingDate == "Invalid date") {
        console.log("cek");
        return toast.error("Tanggal End Mapping Date Wajib Diisi");
      }
      if (editSekolah.limitCard == "Invalid date") {
        console.log("cek");
        return toast.error("Limit Card Wajib Diisi");
      }

      var body = {
        endMappingDate: editSekolah.endMappingDate,
        startMappingDate: editSekolah.startMappingDate,
        limitCard: editSekolah.limitCard,
        selfMapping: editSekolah.selfMapping,
      };

      console.log(body, "aisdsa");
      if (editSekolah.selfMapping == false) {
        body.endMappingDate = editSekolah3.endMappingDate;
        body.startMappingDate = editSekolah3.startMappingDate;
        body.limitCard = editSekolah3.limitCard;
        body.selfMapping = editSekolah3.selfMapping;
      } else {
        body.endMappingDate = editSekolah.endMappingDate;
        body.startMappingDate = editSekolah.startMappingDate;
        body.limitCard = editSekolah.limitCard;
        body.selfMapping = editSekolah.selfMapping;
      }
      editValidator;

      ApiService.postWithData(
        "crmv2/main_school/school/school/configuration/card/" +
          route.params._id,
        body
      )
        .then((res) => {
          toast.success("Data Berhasil di Update ");
          isLoading.value = false;
          router.push({
            path: "/superadmin/sekolah",
          });
        })
        .catch((e) => {
          editSekolah.endMappingDate = editSekolah.endMappingDate;
        editSekolah.startMappingDate = editSekolah.startMappingDate;
        editSekolah.limitCard = editSekolah.limitCard;
        editSekolah.selfMapping = true;
           
          
          toast.error(e.response.data.detail);
        });
    };

    return {
      submitButton,
      editSekolah,
      items,
      isLoading,
      getdata,
      simpan,
      fullPage,
      isColor,
      editSekolah2,
      editSekolah3,
      isBackgroundColor,
      editValidator,
      isOpacity,
      check,
      oncheck,
    };
  },
});
