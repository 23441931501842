
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface updateAfterSalesA {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  note: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  errorprocessed: string;
  erroractorName: string;
  idProgress: string;
}

export default defineComponent({
  name: "Progress After Sales Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0;

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      errorprocessed: "",
      erroractorName: "",
      idProgress: "",
    });

    const updateAfterSales = reactive<updateAfterSalesA>({
      progressType: "After%20Sales",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const tampilAfterSales = reactive<updateAfterSalesA>({
      progressType: "After%20Sales",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const getProgress = () => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          // tampil adalah yg ditampilkan di tabel
          tampilAfterSales.progressDate = res.data.progress.afterSales.progressDate; 
          tampilAfterSales.processed = res.data.progress.afterSales.processed;
          tampilAfterSales.actorName = res.data.progress.afterSales.actorName;
          tampilAfterSales.note = res.data.progress.afterSales.note;

          // update adalah yg ditampilkan di modal form update
          updateAfterSales.progressDate = res.data.progress.afterSales.progressDate; 
          updateAfterSales.processed = res.data.progress.afterSales.processed;
          updateAfterSales.actorName = res.data.progress.afterSales.actorName;
          updateAfterSales.note = res.data.progress.afterSales.note;

          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          }
        });
    };

    const kosongkanVariabel = () => {
      updateAfterSales.progressDate = tampilAfterSales.progressDate;
      updateAfterSales.processed = tampilAfterSales.processed;
      updateAfterSales.actorName = tampilAfterSales.actorName;
      updateAfterSales.note = tampilAfterSales.note;

      // kosongkan variable error message
      items.errorprogressDate = "";
      items.errorprocessed = "";
      items.erroractorName = "";

    };

    const submitAdd = () => {
      if (
        updateAfterSales.progressDate === null ||
        updateAfterSales.progressDate === "" ||
        updateAfterSales.processed === null ||
        updateAfterSales.processed === "" ||
        updateAfterSales.actorName === null || 
        updateAfterSales.actorName === "" 
      ) {
        items.errorprogressDate = "Tanggal Wajib Diisi";
        items.errorprocessed = "Proses Wajib Diisi";
        items.erroractorName = "Penanggung Jawab  Wajib Diisi";
      } else {
        items.errorprogressDate = "";
        items.errorprocessed = "";
        items.erroractorName = "";

        isLoading.value = true;

          // Activate loading indicator
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/progress/" +
              route.params._id +
              "?progressType=" +
              updateAfterSales.progressType +
              "&actorName=" +
              updateAfterSales.actorName +
              "&progressDate=" +
              updateAfterSales.progressDate +
              "&processed=" +
              updateAfterSales.processed +
              "&note=" +
              updateAfterSales.note
          )

            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Update After Sales");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              updateAfterSales.progressDate = ""; 
              updateAfterSales.processed = "";
              updateAfterSales.actorName = "";
              updateAfterSales.note = "";
              
              isLoading.value = false;
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    onMounted(() => {
      getProgress();
    });

    return {
      submitButton,
      getProgress,
      submitAdd,
      items,
      updateAfterSales,
      uploadModalRef,
      tampilAfterSales,
      kosongkanVariabel,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,

    };
  },
});
