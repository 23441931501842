
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";
import { MonthPickerInput } from "vue-month-picker";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import data from "../product/data-order/data";

moment.locale("id");

export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
    MonthPickerInput,
    VueDatePicker,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      isClik: true,
      statusAllFitur: false,
      statusTransaksional: false,
      statusLayananBerbayar: false,
      statusAbsensi: false,
      statusLayananPendukung: false,
      isEdit: false,
      detailFiturSekolah: {
        attendance: false,
        automaticPayment: false,
        blockCardTransaction: false,
        calendarAcademic: false,
        digitalInvoice: false,
        faceRecognition: false,
        ppdb: false,
        scheduleAcademic: false,
        usageStatus: false,
        userAdminSuspensionActiveUntil: "2023-01-31T00:55:33.345Z",
        userAdminSuspensionStatus: "ACTIVE_UNTIL",
      },
      customApps: {
        customApps: false,
        customAppsName: "",
        customAppsFee: 0,
        customAppsPaymentType: "",
        customAppsDocAgreement: "",
        customAppsDueDate: 0,
        customAppsReleaseDate: "",
        customAppsNote: "",
        customAppsStartPaymentDate: "",
        customAppsStart: "",
        customAppsEnd: "",
        customAppsUrl: "",
        customAppsPotongan: "",
      },
      topUpManual: {
        topUpManual: false,
        topUpManualDocAgreement: "",
        topUpManualDueDate: 10,
        topUpManualFee: 0,
        topUpManualFeeSchool: 0,
        topUpManualFeeMerchant: 0,
        totalTopUpManualFee: 0,
        topUpManualNote: "",
      },
      modalFitur: {
        textTittleModal: "",
        nameFitur: "",
        valueFitur: false,
      },
      targetData: {
        uploadTopUp: false,
        fileTopUp: "",
        uploadCustomApps: false,
        fileCustomApps: "",
      },
      dateAll: {},
      fileInputKey: 0,
      modalTopUp: false,
      modalCustomApp: false,
      modalPPDB: false,

      isDisabled: false,

      errorMsg: {
        biayaTopUp: "",
        uploadDokTopUp: "",
        namaAplikasiCustomApp: "",
        customAppsStartPaymentDate: "",
        biayaCustomApp: "",
        tipePembayaranCustomApp: "",
        customAppsPaymentType: "",
        uploadDokCustomApp: "",
        errorTglPks: "",
        error: "",
        errorFile: "",
        errorTopUpBiayaAdmindariSekolah: "",
        errorTopupMerchant: "",

        errorCatatan: "",
        errorCustomAppsFee: "",
      },

      rsltCekUpload: false,
      errorRsltUpload: "",
      customAppsDocAgreement: "",
      selectedDate: null,
      dataUpload: "",
    };
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
    this.getdetail();
  },

  methods: {
    async getdetail() {
      this.isLoading = true;
      const toast = useToast();

      await ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/feature/" + this.$route.params._id
      )
        .then((res) => {
          this.detailFiturSekolah = Object.assign({}, res.data);
          this.customApps = Object.assign({}, res.data.customApps);
          var a = [
            this.customApps.customAppsStart,
            this.customApps.customAppsEnd,
          ];
          this.dateAll = a;
          this.topUpManual = Object.assign({}, res.data.topUpManual);
          this.topUpManual.topUpManualDueDate = 10; //hardcode 03 Feb Selalu 10
          this.customApps.customAppsDueDate = 10; //hardcode 03 Feb Selalu 10
          this.customAppsDocAgreement =
            res.data.customApps.customAppsDocAgreement;
          this.topUpManual.totalTopUpManualFee =
            res.data.topUpManual.topUpManualFeeSchool +
            res.data.topUpManual.topUpManualFee +
            res.data.topUpManual.topUpManualFeeMerchant;
          this.modalFitur.valueFitur = res.data.customApps.customApps;

          // console.log(res.data.customApps.customApps,"aaaa")
          if (
            res.data.topUpManual.topUpManualDocAgreement == "" ||
            res.data.topUpManual.topUpManualDocAgreement == null
          ) {
            this.topUpManual.topUpManualDocAgreement = "";
          }

          this.checkAllFitur();

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    async getCustomPrice() {
      this.isLoading = true;
      const toast = useToast();

      const idSettingInv = ApiService.idSettingInvoice();
      await ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/office/invoice/settings/" +
        idSettingInv +
        "?schoolId=" +
        this.$route.params._id
      )
        .then((res) => {
          if (res.data.custom.length > 0) {
            this.topUpManual.topUpManualFee = res.data.custom[0].price;
          } else {
            this.topUpManual.topUpManualFee = res.data.defaultPrice;
          }

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    handlestartdatenull(e) {
      if (
        e.target.value.length === 0 ||
        e.target.value == null ||
        e.target.value == ""
      ) {
        this.customApps.customAppsStartPaymentDate = "";
      }
      console.log(e.target.value, "tu");
      this.fileInputKey++;

      this.isEdit = true;
    },

    updateFiturAll() {
      this.isLoading = true;
      const toast = useToast();
      if (this.customApps.customAppsReleaseDate) {
        this.customApps.customAppsReleaseDate = moment(
          this.customApps.customAppsReleaseDate
        ).format("YYYY-MM-DD");
      } else {
        this.customApps.customAppsReleaseDate = "";
      }
      if (this.customApps.customAppsStartPaymentDate) {
        this.customApps.customAppsStartPaymentDate = moment(
          this.customApps.customAppsStartPaymentDate
        )
          .endOf("months")
          .format("YYYY-MM-DD");
      }
      if (this.customApps.customAppsStart) {
        this.customApps.customAppsStart = moment(
          this.customApps.customAppsStart
        ).format("YYYY-MM-DD");
      }
      if (this.customApps.customAppsEnd) {
        this.customApps.customAppsEnd = moment(
          this.customApps.customAppsEnd
        ).format("YYYY-MM-DD");
      }

      let dataValue = {
        usageStatus: this.detailFiturSekolah.usageStatus,
        userAdminSuspensionStatus:
          this.detailFiturSekolah.userAdminSuspensionStatus,
        userAdminSuspensionActiveUntil:
          this.detailFiturSekolah.userAdminSuspensionActiveUntil,
        digitalInvoice: this.detailFiturSekolah.digitalInvoice,
        automaticPayment: this.detailFiturSekolah.automaticPayment,
        blockCardTransaction: this.detailFiturSekolah.blockCardTransaction,
        ppdb: this.detailFiturSekolah.ppdb,
        attendance: this.detailFiturSekolah.attendance,
        faceRecognition: this.detailFiturSekolah.faceRecognition,
        calendarAcademic: this.detailFiturSekolah.calendarAcademic,
        scheduleAcademic: this.detailFiturSekolah.scheduleAcademic,
        topUpManual: this.topUpManual,
        customApps: this.customApps,
      };

      let customApps = { customApps: this.customApps };
      let manualTopUp = { topUpManual: this.topUpManual };
      let ppdb = { ppdb: this.detailFiturSekolah.ppdb };
      let blockCardTransaction = {
        blockCardTransaction: this.detailFiturSekolah.blockCardTransaction,
      };
      let automaticPayment = {
        automaticPayment: this.detailFiturSekolah.automaticPayment,
      };
      let digitalInvoice = {
        digitalInvoice: this.detailFiturSekolah.digitalInvoice,
      };
      let attendance = { attendance: this.detailFiturSekolah.attendance };
      let faceRecognition = {
        faceRecognition: this.detailFiturSekolah.faceRecognition,
      };
      let calendarAcademic = {
        calendarAcademic: this.detailFiturSekolah.calendarAcademic,
      };
      let scheduleAcademic = {
        scheduleAcademic: this.detailFiturSekolah.scheduleAcademic,
      };

      let datalama = {
        customApps: {
          customApps: false,
          customAppsName: "",
          customAppsFee: 0,
          customAppsPaymentType: "",
          customAppsDocAgreement: "",
          fileInputKey: 0,
          customAppsDueDate: 0,
          customAppsReleaseDate: "",
          customAppsNote: "",
          customAppsStartPaymentDate: "",
          customAppsStart: "",
          customAppsEnd: "",
          customAppsUrl: "",
          customAppsPotongan: "",
        },
      };
      // if (customApps.customApps.customAppsStart == "Invalid date") {
      //   customApps.customApps.customAppsStart = null;
      // }
      // if (customApps.customApps.customAppsEnd == "Invalid date") {
      //   customApps.customApps.customAppsEnd = null;
      // }
      let data: any = this.modalCustomApp
        ? customApps
        : this.modalPPDB
          ? ppdb
          : this.modalTopUp
            ? manualTopUp
            : this.modalFitur.nameFitur === "blockCardTransaction"
              ? blockCardTransaction
              : this.modalFitur.nameFitur === "automaticPayment"
                ? automaticPayment
                : this.modalFitur.nameFitur === "digitalInvoice"
                  ? digitalInvoice
                  : this.modalFitur.nameFitur === "attendance"
                    ? attendance
                    : this.modalFitur.nameFitur === "faceRecognition"
                      ? faceRecognition
                      : this.modalFitur.nameFitur === "calendarAcademic"
                        ? calendarAcademic
                        : this.modalFitur.nameFitur === "scheduleAcademic"
                          ? scheduleAcademic
                          : dataValue;


      ApiService.putWithData(
        "crmv2/main_school/school/school/feature/" + this.$route.params._id,
        data
      )
        .then(({ data }) => {
          this.isLoading = false;
          this.getdetail();
          this.closeModal2();
          this.fileInputKey++;

          toast.success("Berhasil Ubah");
          console.log("dfjruhb");
          this.errorMsg.errorTopupMerchant = "";
        })
        .catch((errors) => {
          this.isLoading = false;
          // this.customApps.customAppsDocAgreement = "";
          toast.error(errors.response?.data?.detail);
          console.log("erey");
        });
    },

    async uploadData(valueData, type) {
      this.isLoading = true;
      const toast = useToast();

      let formData = new FormData();
      formData.append("uploaded_file", valueData);

      await ApiService.postWithDataKatalis(
        "main_a/file/" + this.$route.params._id,
        formData
      )
        .then((res) => {
          console.log(res);

          if (type == "TopUpManual") {
            this.topUpManual.topUpManualDocAgreement = res.data.fileName;
          }
          if (type == "CustomApps") {
            this.customApps.customAppsDocAgreement = res.data.fileName;
          }
          this.fileInputKey++;
          this.isLoading = false;

          this.rsltCekUpload = true;

          console.log("cek2.2");

          console.log("first", this.topUpManual.topUpManualDocAgreement);
        })
        .catch((e) => {
          this.isLoading = false;
          this.errorRsltUpload = e.response.data.detail;
        });
    },
    async downloadData(namafile, newNama, extension) {
      this.isLoading = true;
      const toast = useToast();

      await ApiService.getDownloadKatalis(
        "main_a/file/" + this.$route.params._id + "/" + namafile
      )
        .then((res) => {
          this.handleBlobResponse(res.data, namafile, newNama, extension);

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          toast.error("File Dokumen Tidak Ditemukan");
        });
    },
    reset(event) {
      this.customApps.customAppsStart = "";
      this.customApps.customAppsEnd = "";
    },
    pilihType(e) {
      console.log(e.target.value);
      this.isEdit = true;
      this.customApps.customAppsFee = 0;
      this.errorMsg.customAppsPaymentType = ""
      this.pilihTanggalAll();
    },
    pilihpotongan() { },
    pilihTanggalAll() {
      this.isEdit = true;
      if (this.dateAll == null) {
        console.log(this.dateAll, "lalayeyye");
        this.dateAll = [];
        this.customApps.customAppsStart = "";
        this.customApps.customAppsEnd = "";
      } else {
        console.log(this.dateAll, "ooo");
        this.customApps.customAppsStart = this.dateAll[0];
        this.customApps.customAppsEnd = this.dateAll[1];
        const startMoment = moment(
          this.customApps.customAppsStart,
          "YYYY-MM-DD"
        );
        const endMoment = moment(this.customApps.customAppsEnd, "YYYY-MM-DD");
        var cek = endMoment.diff(startMoment, "months");
        this.errorMsg.errorTglPks = "";
      }
    },
    async emptyNull(e) {
      var intRegex = new RegExp(/^\d+$/);
      var data = intRegex.test(e.target.value);
      if (!data) {
        if (this.topUpManual.topUpManualFeeMerchant < 0) {
          this.errorMsg.errorTopupMerchant = "Tidak Boleh kurang dari 0";
          this.topUpManual.topUpManualFeeMerchant = 0;
        } else {
          this.topUpManual.topUpManualFeeMerchant = 0;
        }
      }
    },

    async setValueUpdateModal(valueName, value, textTittle) {
      this.modalFitur.textTittleModal = textTittle;
      this.modalFitur.nameFitur = valueName;
      this.modalFitur.valueFitur = value;
      this.errorMsg.error = "";
      this.errorMsg.errorFile = "";
      this.errorMsg.errorCustomAppsFee = "";
      if (valueName == "topUpManual") {
        this.modalTopUp = true;
        await this.getdetail();
        await this.getCustomPrice();
        this.targetData.uploadTopUp = false;
        value == true ? (this.isDisabled = false) : (this.isDisabled = true);
      }
      if (valueName == "customApps") {
        this.modalCustomApp = true;
        this.getdetail();
        this.targetData.uploadCustomApps = false;
        value == true ? (this.isDisabled = false) : (this.isDisabled = true);
      }
      if (valueName == "ppdb") {
        this.modalPPDB = true;
      }
    },
    saveModalConfirmation() {
      switch (this.modalFitur.nameFitur) {
        case "attendance":
          this.detailFiturSekolah.attendance = this.modalFitur.valueFitur;
          break;
        case "automaticPayment":
          this.detailFiturSekolah.automaticPayment = this.modalFitur.valueFitur;
          break;
        case "blockCardTransaction":
          this.detailFiturSekolah.blockCardTransaction =
            this.modalFitur.valueFitur;
          break;
        case "calendarAcademic":
          this.detailFiturSekolah.calendarAcademic = this.modalFitur.valueFitur;
          break;
        case "digitalInvoice":
          this.detailFiturSekolah.digitalInvoice = this.modalFitur.valueFitur;
          break;
        case "faceRecognition":
          this.detailFiturSekolah.faceRecognition = this.modalFitur.valueFitur;
          break;
        case "ppdb":
          this.detailFiturSekolah.ppdb = this.modalFitur.valueFitur;
          break;
        case "scheduleAcademic":
          this.detailFiturSekolah.scheduleAcademic = this.modalFitur.valueFitur;
          break;
        case "usageStatus":
          this.detailFiturSekolah.usageStatus = this.modalFitur.valueFitur;
          break;
        case "customApps":
          this.customApps.customApps = this.modalFitur.valueFitur;
          break;
        case "topUpManual":
          this.topUpManual.topUpManual = this.modalFitur.valueFitur;
          break;
      }
      this.checkAllFitur();
      this.updateFiturAll();
    },
    checkAllFitur() {
      if (
        this.detailFiturSekolah.digitalInvoice == true ||
        this.detailFiturSekolah.automaticPayment == true ||
        this.detailFiturSekolah.blockCardTransaction == true
      ) {
        this.statusTransaksional = true;
      } else {
        this.statusTransaksional = false;
      }

      if (
        this.customApps.customApps == true ||
        this.topUpManual.topUpManual == true ||
        this.detailFiturSekolah.ppdb == true
      ) {
        this.statusLayananBerbayar = true;
      } else {
        this.statusLayananBerbayar = false;
      }

      if (
        this.detailFiturSekolah.attendance == true ||
        this.detailFiturSekolah.faceRecognition == true
      ) {
        this.statusAbsensi = true;
      } else {
        this.statusAbsensi = false;
      }

      if (
        this.detailFiturSekolah.calendarAcademic == true ||
        this.detailFiturSekolah.scheduleAcademic == true
      ) {
        this.statusLayananPendukung = true;
      } else {
        this.statusLayananPendukung = false;
      }

      if (
        this.statusTransaksional == true ||
        this.statusLayananBerbayar == true ||
        this.statusAbsensi == true ||
        this.statusLayananPendukung == true
      ) {
        this.statusAllFitur = true;
      } else {
        this.statusAllFitur = false;
      }
    },
    saveUpdateFitur() {
      this.updateFiturAll();
    },
    validateInput() {
      if (isNaN(this.topUpManual.topUpManualFee)) {
        this.topUpManual.topUpManualFee = 0;
      }
    },
    async saveTopUpManual() {
      const toast = useToast();
      var a = this.topUpManual.topUpManualFee + "";
      console.log(a);
      if (a === "") {
        this.topUpManual.topUpManualFee = 0;
      }
      console.log(this.topUpManual.topUpManualFee, "cek");
      if (this.modalFitur.valueFitur == true) {
        if (this.topUpManual.topUpManualFee == null) {
          this.topUpManual.topUpManualFee = 0;
        } else if (this.topUpManual.topUpManualFee < 0) {
          this.errorMsg.error = "Tidak Boleh kurang dari 0";
        } else if (this.topUpManual.topUpManualFeeSchool < 0) {
          this.errorMsg.errorTopUpBiayaAdmindariSekolah =
            "Tidak Boleh kurang dari 0";
          toast.error("Field Tidak Boleh kurang dari 0");
        } else if (this.topUpManual.topUpManualFeeMerchant < 0) {
          this.errorMsg.errorTopupMerchant = "Tidak Boleh kurang dari 0";
          toast.error("Field Tidak Boleh kurang dari 0");

          this.topUpManual.topUpManualFeeMerchant = 0;
        } else {
          await this.checkFileUpload(
            this.topUpManual.topUpManualDocAgreement,
            this.targetData.uploadTopUp,
            this.targetData.fileTopUp,
            "TopUpManual"
          );

          if (this.rsltCekUpload == false) {
            if (this.errorRsltUpload == "") {
              toast.error("Field required (*) wajib diisi");
              this.errorMsg.errorFile = "Wajib Diisi. Tidak Boleh Kosong";
              console.log("kosong-->");
            } else {
              toast.error(this.errorRsltUpload);
              this.errorMsg.errorFile = this.errorRsltUpload;
            }
          } else {
            if (!this.topUpManual.topUpManualFeeSchool) {
              this.topUpManual.topUpManualFeeSchool = 0;
            }
            this.topUpManual.topUpManual = this.modalFitur.valueFitur;
            console.log("last", this.topUpManual.topUpManualDocAgreement);
            await this.updateFiturAll();
          }
        }
      } else {
        this.topUpManual.topUpManual = this.modalFitur.valueFitur;
        console.log("b");
        this.updateFiturAll();
      }
    },
    async saveCustomApps() {
      const toast = useToast();
      console.log(this.customApps, "ddd");
      // if (this.modalFitur.valueFitur == true) {
      //   if (
      //     this.customApps.customAppsName == "" ||
      //     this.customApps.customAppsName == null ||
      //     this.customApps.customAppsFee < 0 ||
      //     this.customApps.customAppsPaymentType == "" ||
      //     this.customApps.customAppsPaymentType == null ||
      //     this.customApps.customAppsStart == "" ||
      //     this.customApps.customAppsEnd == null
      //   ) {
      //     if (
      //       this.customApps.customAppsName == "" ||
      //       this.customApps.customAppsName == null
      //     ) {
      //       this.customApps.customAppsName = "";
      //     }

      //     if (
      //       this.customApps.customAppsPaymentType == "" ||
      //       this.customApps.customAppsPaymentType == null
      //     ) {
      //       this.customApps.customAppsPaymentType = "";
      //     }

      //     if (
      //       (this.customApps.customAppsNote == "" &&
      //         this.customApps.customAppsPaymentType == "GRATIS") ||
      //       this.customApps.customAppsPaymentType == "SEKALI_BAYAR"
      //     ) {
      //       this.errorMsg.errorCatatan =
      //         "Wajib Diisi Ketika Tipe Pembayaran Gratis/Sekali Bayar";
      //     }
      //     if (
      //       this.customApps.customAppsFee == Number("") &&
      //       this.customApps.customAppsFee < 0
      //     ) {
      //       this.errorMsg.errorCustomAppsFee = "Input Minimal 0";
      //     }

      //     this.errorMsg.error = "Wajib Diisi. Tidak Boleh Kosong";

      //     // if (this.customApps.customAppsStart)
      //     //   this.customApps.customAppsStart = moment(
      //     //     this.customApps.customAppsStart
      //     //   ).format("YYYY-MM-DD");
      //     // if (this.customApps.customAppsEnd)
      //     //   this.customApps.customAppsEnd = moment(
      //     //     this.customApps.customAppsEnd
      //     //   ).format("YYYY-MM-DD");

      //     this.fileInputKey++;
      //   } else {
      //     await this.checkFileUpload(
      //       this.customApps.customAppsDocAgreement,
      //       this.targetData.uploadCustomApps,
      //       this.targetData.fileCustomApps,
      //       "CustomApps"
      //     );
      //     if (this.rsltCekUpload == false) {
      //       if (this.errorRsltUpload == "") {
      //         toast.error("Field required (*) wajib diisi");
      //         this.errorMsg.errorFile = "Wajib Diisi. Tidak Boleh Kosong";
      //       } else {
      //         toast.error(this.errorRsltUpload);
      //         this.errorMsg.errorFile = this.errorRsltUpload;
      //       }
      //     } else {
      //       this.customApps.customApps = this.modalFitur.valueFitur;
      //       if (this.customApps.customAppsStart)
      //         this.customApps.customAppsStart = moment(
      //           this.customApps.customAppsStart
      //         ).format("YYYY-MM-DD");

      //       if (this.customApps.customAppsEnd)
      //         this.customApps.customAppsEnd = moment(
      //           this.customApps.customAppsEnd
      //         ).format("YYYY-MM-DD");

      //       this.updateFiturAll();
      //     }
      //   }
      // } else {
      console.log(this.customApps.customAppsEnd, "aaa");
      this.customApps.customApps = this.modalFitur.valueFitur;
      await this.checkFileUpload(
        this.customApps.customAppsDocAgreement,
        this.targetData.uploadCustomApps,
        this.targetData.fileCustomApps,
        "CustomApps"
      );
      if (this.customApps.customAppsStart)
        this.customApps.customAppsStart = moment(
          this.customApps.customAppsStart
        ).format("YYYY-MM-DD");
      if (this.customApps.customAppsEnd)
        this.customApps.customAppsEnd = moment(
          this.customApps.customAppsEnd
        ).format("YYYY-MM-DD");

      if (this.customApps.customAppsNote == "" && this.customApps.customAppsPaymentType == "GRATIS") {
        this.errorMsg.errorCatatan =
          "Wajib Diisi Ketika Tipe Pembayaran Gratis/Sekali Bayar";
      }
      if (this.customApps.customAppsNote == "" && this.customApps.customAppsPaymentType == "SEKALI_BAYAR") {
        this.errorMsg.errorCatatan =
          "Wajib Diisi Ketika Tipe Pembayaran Gratis/Sekali Bayar";
      }

      if (
        this.customApps.customAppsPaymentType == "" ||
        this.customApps.customAppsPaymentType == null
      ) {
        return this.errorMsg.customAppsPaymentType = "Tipe Pembayaran Wajib Di isi";
      }

      this.updateFiturAll();
      // }
    },

    clearErrorMessage(e) {
      console.log(e.target.value, "tuuu");
      var datenow = moment().format("YYYY-MM-DD");
      this.isEdit = true;
      if (e.target.value) {
        console.log(e.target.value);
        this.customApps.customAppsStartPaymentDate = e.target.value;
        let as = moment(datenow).startOf("month").format("YYYY-MM-DD");
        let b = moment(e.target.value).endOf("month").format("YYYY-MM-DD");
        var startMoment = moment(as, "YYYY-MM-DD");
        var endMoment = moment(b, "YYYY-MM-DD");

        this.errorMsg.customAppsStartPaymentDate = "";
        if (this.customApps.customAppsPaymentType == "TAHUNAN") {
          this.customApps.customAppsPotongan =
            endMoment.diff(startMoment, "years").toString() + " Tahun";
        } else {
          this.customApps.customAppsPotongan =
            endMoment.diff(startMoment, "months").toString() + " Bulan";
        }
      } else {
        this.customApps.customAppsPotongan = "";
        this.errorMsg.customAppsStartPaymentDate =
          "Wajib Diisi. Tidak Boleh Kosong";
      }
    },
    closeModal2() {
      this.modalTopUp = false;
      this.modalCustomApp = false;
      this.modalPPDB = false;
      this.isEdit = false;
      this.isClik = true;
      this.errorMsg.errorTopupMerchant = "";
      this.errorMsg.errorTglPks = "";
      this.customApps = {
        customApps: false,
        customAppsName: "",
        customAppsFee: 0,
        customAppsPaymentType: "",
        customAppsDocAgreement: "",
        customAppsDueDate: 0,
        customAppsReleaseDate: "",
        customAppsNote: "",
        customAppsStartPaymentDate: "",
        customAppsStart: "",
        customAppsEnd: "",
        customAppsUrl: "",
        customAppsPotongan: "",
      };
      this.errorMsg = {
        biayaTopUp: "",
        uploadDokTopUp: "",
        namaAplikasiCustomApp: "",
        customAppsStartPaymentDate: "",
        biayaCustomApp: "",
        tipePembayaranCustomApp: "",
        uploadDokCustomApp: "",
        errorTglPks: "",
        error: "",
        errorFile: "",
        errorTopUpBiayaAdmindariSekolah: "",
        errorTopupMerchant: "",
        customAppsPaymentType: '',
        errorCatatan: "",
        errorCustomAppsFee: "",
      };
    },
    closeModal(e) {
      this.modalTopUp = false;
      this.modalCustomApp = false;
      this.modalPPDB = false;
      this.isEdit = false;
      this.isClik = true;
      this.errorMsg.errorTopupMerchant = "";
      this.errorMsg.errorTglPks = "";
      this.customApps = {
        customApps: false,
        customAppsName: "",
        customAppsFee: 0,
        customAppsPaymentType: "",
        customAppsDocAgreement: "",
        customAppsDueDate: 0,
        customAppsReleaseDate: "",
        customAppsNote: "",
        customAppsStartPaymentDate: "",
        customAppsStart: "",
        customAppsEnd: "",
        customAppsUrl: "",
        customAppsPotongan: "",
      };
      this.errorMsg = {
        biayaTopUp: "",
        uploadDokTopUp: "",
        namaAplikasiCustomApp: "",
        customAppsStartPaymentDate: "",
        biayaCustomApp: "",
        tipePembayaranCustomApp: "",
        uploadDokCustomApp: "",
        errorTglPks: "",
        error: "",
        errorFile: "",
        errorTopUpBiayaAdmindariSekolah: "",
        errorTopupMerchant: "",
        customAppsPaymentType: "",
        errorCatatan: "",
        errorCustomAppsFee: "",
      };
      this.getdetail();
      e.preventDefault();
    },
    gagal() {
      let toast = useToast();
      toast.error("Belum Ada Perubahan");
    },

    activeRadioButtonSelected() {
      this.errorMsg.error = "";
      this.isEdit = true;
      this.isDisabled = false;
    },
    nonActiveRadioButtonSelected() {
      this.errorMsg.error = "";
      this.isEdit = true;
      this.isDisabled = true;
    },

    downloadDoc(value, newNama) {
      this.isEdit = true;
      this.downloadData(value, newNama, this.getExtensionFile(value));
    },

    handleFileUploadTopUp(event) {
      if (event) {
        this.targetData.fileTopUp = event.target.files[0];
        this.targetData.uploadTopUp = true;
        this.errorMsg.errorFile = "";
      } else {
        this.targetData.uploadTopUp = false;
        this.errorMsg.errorFile = "Field required (*) wajib diisi";
      }
    },

    handleFileUploadCustomApps(event) {
      var toast = useToast();
      console.log(event.target.files[0]);

      const allowedFormats = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/doc",
        "application/ms-doc",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (event) {
        this.targetData.fileCustomApps = event.target.files[0];

        if (!allowedFormats.includes(event.target.files[0].type)) {
          this.errorMsg.errorFile =
            "Format harus JPG, JPEG, PDF, PNG, DOC, dan DOCX ";
        } else {
          this.targetData.uploadCustomApps = true;
          this.errorMsg.errorFile = "";
        }
      } else {
        this.targetData.uploadCustomApps = false;
      }
    },
    keydown(e) {
      this.isEdit = true;
      if (e.target.value.length == 0) {
        this.customApps.customAppsFee = 0;
      }
    },
    handleBlobResponse(blobFileData, namafile, newNama, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", newNama + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },
    catatan() {
      this.isEdit = true;
      this.errorMsg.errorCatatan = "";
    },
    formatTanggal(e) {
      return moment(e).format("MMMM-YYYY");
    },
    changebiaya() {
      this.topUpManual.totalTopUpManualFee =
        Number(this.topUpManual.topUpManualFeeSchool) +
        Number(this.topUpManual.topUpManualFee) +
        Number(this.topUpManual.topUpManualFeeMerchant);
    },

    async checkFileUpload(oldFileUpload, statusNewUpload, newFileUpload, type) {
      if (oldFileUpload == "" || oldFileUpload == null) {
        if (statusNewUpload == true) {
          this.errorRsltUpload = "";
          await this.uploadData(newFileUpload, type);
        } else {
          this.rsltCekUpload = false;
        }
      } else {
        if (statusNewUpload == true) {
          this.errorRsltUpload = "";
          await this.uploadData(newFileUpload, type);
        } else {
          this.rsltCekUpload = true;
        }
      }
    },

    getExtensionFile(val) {
      if (val) {
        const myArray = val.split(".");
        return myArray[1];
      } else {
        return "-";
      }
    },
    showDate(date) {
      this.customApps.customAppsStartPaymentDate = date;
    },
  },
});
