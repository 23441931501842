
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

interface updatePKSAB {
  progressType: string;
  progressDate: string;
  actorName: string;
  processed: string;
  contractStatus: string;
  note: string;
  requestedStatus: string;
  sales: string;

  file: string;
  file2: string;
}

interface updatePKSA {
  progressType: string;
  progressDate: string;
  actorName: string;
  processed: string;
  contractStatus: string;
  note: string;
  role: any;
  file: string;
  file2: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  erroractorName: string;
  errorcontractStatus: string;
  idProgress: string;
  fileInputKey: any;
  fileInputKey2: any;
  role: any;
  confirmationLetterFile: any;
  piroDocumentFile: string;
}

export default defineComponent({
  name: "Progress PKS Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0;

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      erroractorName: "",
      errorcontractStatus: "",
      idProgress: "",
      fileInputKey: 0,
      fileInputKey2: 0,
      role: "",
      confirmationLetterFile: "",
      piroDocumentFile: "",
    });

    const updatePKS = reactive<updatePKSA>({
      progressType: "Contract",
      progressDate: "",
      actorName: "",
      processed: "false",
      contractStatus: "",
      note: "",
      role: "",
      file: "",
      file2: "",
    });

    const tampilPKS = reactive<updatePKSAB>({
      progressType: "Contract",
      progressDate: "",
      actorName: "",
      processed: "",
      contractStatus: "",
      note: "",
      requestedStatus: "",

      file: "",
      file2: "",
      sales: "",
    });

    const handleFileUpload = (event) => {
      const val = event.target.files[0].name;
      const myArray = val.split(".");
      if (myArray[1] == "docx" || myArray[1] == "doc") {
        updatePKS.file = event.target.files[0];
      } else {
        updatePKS.file = "";
        items.fileInputKey++;

        toast.error("Ekstensi Dokumen Tidak Sesuai (doc/docx)");
      }
    };

    const handleFileUpload2 = (event) => {
      const val = event.target.files[0].name;
      const myArray = val.split(".");
      if (myArray[1] == "xlsx" || myArray[1] == "xls") {
        updatePKS.file2 = event.target.files[0];
      } else {
        updatePKS.file2 = "";
        items.fileInputKey2++;

        toast.error("Ekstensi Dokumen Tidak Sesuai (xls/xlsx)");
      }
    };

    const getProgress = () => {
      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          // tampil adalah yg ditampilkan di tabel
          tampilPKS.progressDate = res.data.progress.contract.progressDate;
          tampilPKS.actorName = res.data.progress.contract.actorName;
          tampilPKS.contractStatus = res.data.progress.contract.status;
          tampilPKS.note = res.data.progress.contract.note;
          tampilPKS.requestedStatus =
            res.data.progress.contract.requestedStatus;
          tampilPKS.sales = res.data.progress.contract.salesArea;

          // update adalah yg ditampilkan di modal form update
          updatePKS.progressDate = res.data.progress.contract.progressDate;
          updatePKS.actorName = res.data.progress.contract.actorName;
          updatePKS.contractStatus = res.data.progress.contract.status;
          updatePKS.note = res.data.progress.contract.note;

          items.confirmationLetterFile =
            res.data.progress.contract.confirmationLetterFile;
          items.piroDocumentFile = res.data.progress.contract.piroDocumentFile;

          isLoading.value = false;
        })
        .catch((e) => {
          // toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const CancelUpdate = () => {
      updatePKS.progressDate = tampilPKS.progressDate;
      updatePKS.actorName = tampilPKS.actorName;
      updatePKS.contractStatus = tampilPKS.contractStatus;
      updatePKS.note = tampilPKS.note;

      // kosongkan variable error message
      items.errorprogressDate = "";
      items.errorcontractStatus = "";
      items.erroractorName = "";

      updatePKS.file = "";
      updatePKS.file2 = "";
      items.fileInputKey++;
      items.fileInputKey2++;
    };

    const submitAddPKS = () => {
      if (
        updatePKS.progressDate === null ||
        updatePKS.progressDate === "" ||
        updatePKS.actorName === null ||
        updatePKS.actorName === "" ||
        updatePKS.contractStatus === null ||
        updatePKS.contractStatus === ""
      ) {
        items.errorprogressDate = "Tanggal Wajib Diisi";
        items.erroractorName = "Penanggung Jawab  Wajib Diisi";
        items.errorcontractStatus = "Status PKS Wajib Diisi";
      } else {
        items.errorprogressDate = "";
        items.erroractorName = "";
        items.errorcontractStatus = "";

        isLoading.value = true;

        let formData = new FormData();

        formData.append("confLetterFile", updatePKS.file);
        formData.append("piroDocument", updatePKS.file2);

        // Send login request
        ApiService.putWithData(
          "crmv2/main_school/school/school/progress/" +
            route.params._id +
            "?progressType=" +
            updatePKS.progressType +
            "&actorName=" +
            updatePKS.actorName +
            "&progressDate=" +
            updatePKS.progressDate +
            "&processed=" +
            updatePKS.processed +
            "&contractStatus=" +
            updatePKS.contractStatus +
            "&note=" +
            updatePKS.note,
          formData
        )

          .then((res) => {
            hideModal(uploadModalRef.value);
            toast.success("Berhasil Update PKS");
            submitButton.value?.removeAttribute("data-kt-indicator");
            getProgress();

            items.disableButton = false;

            updatePKS.progressDate = "";
            updatePKS.actorName = "";
            updatePKS.contractStatus = "";
            updatePKS.note = "";

            updatePKS.file = "";
            updatePKS.file2 = "";
            items.fileInputKey++;
            items.fileInputKey2++;

            isLoading.value = false;
          })
          .catch((e) => {
            //             if (items.role == "SALES_AREA" || items.role == "SALES_LEADER"
            //             toast.error("Sales Sekolah Tidak Boleh Kosong !")
            // }
            items.disableButton = false;
            submitButton.value?.removeAttribute("data-kt-indicator");

            toast.error(e.response.data.detail);

            isLoading.value = false;
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const downloadCL = () => {
      window.open(
        "https://api.teknologikartu.com/crmv2/main_school/school/school/download_school/confirmationLetter/" +
          route.params._id,
        "_blank"
      );
    };

    const downloadPiro = () => {
      window.open(
        "https://api.teknologikartu.com/crmv2/main_school/school/school/download_school/piro/" +
          route.params._id,
        "_blank"
      );
    };

    onMounted(() => {
      getProgress();
    });

    return {
      submitButton,
      getProgress,
      submitAddPKS,
      items,
      updatePKS,
      uploadModalRef,
      tampilPKS,
      CancelUpdate,

      handleFileUpload,
      handleFileUpload2,

      downloadCL,
      downloadPiro,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
