
import { defineComponent, onMounted, ref, reactive, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";


export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
    Multiselect,
  },

  data() {
    return {
      submitButton: ref<HTMLElement | null>(null),
      toast: useToast(),
      router: useRouter(),
      route: useRoute(),
      store: useStore(),

      isLoading: ref(false),
      fullPage: ref(true),
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      items: {
        listBank: [{
          _id: "",
          name: ""
        }],
        listArea: [{
          areaId: "", _id: "", areaName: ""
        }],
        listSales: [],
        listBranch: [{
          _id: "", branchId: "", branchName: ""
        }],
        ListSalesArea: [],
        listFunding: [],
        provinsiOptions: [{ name: "", id: "" }],
        kabupatenOptions: [{ name: "", id: "" }],
        kecamatanOptions: [{ name: "", id: "" }],
        user_sales_area: [{
          _id: "", regions: false, name: ""
        }],
        user_implementator: [{
          _id: "", name: ""
        }],
        dataFitur: [
          {
            value: false,
            label: "Absensi Wajah",
            nama: "faceRecognition",
          },
          {
            value: false,
            label: "Auto Block Transaksi",
            nama: "blockCardTransaction",
          },
          {
            value: false,
            label: "Pembayaran Otomatis",
            nama: "automaticPayment",
          },
        ],
        disableButton: false,
        url: "",
        role: "",

        errorschoolName: "",
        errorprovince: "",
        errorcity: "",
        errorregency: "",
        errordetail: "",
        erroremail: "",
      },

      editSekolahValidator: Yup.object().shape({
        name: Yup.string().required("Nama Sekolah Wajib Diisi"),
        email: Yup.string().email("Email Harus Valid"),
        picEmail: Yup.string().email("Email Harus Valid"),
        province: Yup.string().required("Provinsi Wajib Diisi"),
        city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
        regency: Yup.string().required("Kecamatan Wajib Diisi"),
        detail: Yup.string().required("Alamat Wajib Diisi"),
      }),

      tambahNpwp: {
        idSekolah: "",
        file: "",
      },

      editSekolah: {
        name: "",
        phone: "",
        email: "",
        initial: "",
        fundingName: "",
        picName: "",
        picPhone: "",
        picEmail: "",
        schoolType: "",
        picProduct: "",
        status: "",
        targetLive: "",
        customApp: "",
        address: {
          province: "",
          city: "",
          regency: "",
          detail: "",
        },
        bankAreaId: null,
        bankBranchId: null,
        bankData: [],
        implementatorData: [],
        gps: {
          longitude: "",
          latitude: "",
        },
        npwp: {
          number: "",
          imageUrl: "",
        },
        referenceFrom: "",
        note: "",
        salesArea: "",
        topupFee: 0,
        automaticPayment: false,
        blockCardTransaction: false,
        faceRecognition: false,
      },
    }
  },
  mounted() {
    this.store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
    // this.getProvinsi();
    this.getIdSekolah();
    this.items.url =
      ApiService.vueInstance.axios.defaults.baseURL +
      "crmv2/main_school/school/school/download_npwp_by_file?schoolId=" +
      this.route.params._id +
      "&imgFile=" +
      this.editSekolah.npwp.imageUrl;
    this.items.role = JSON.parse(localStorage.getItem("user_account")!).role;

    this.getListBank();

    this.getListFunding();

    this.getListSalesArea();
    this.combo_user_implementator();
  },
  methods: {
    handleFileUpload(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          // this.foto =
          this.items.url = "" + e.target?.result;
        };
        this.tambahNpwp.file = input.files[0];
        // this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },

    saveChanges1() {
      if (
        this.editSekolah.initial == "" ||
        this.editSekolah.name === "" ||
        this.editSekolah.address.province === "" ||
        this.editSekolah.address.city === "" ||
        this.editSekolah.address.regency === "" ||
        this.editSekolah.address.detail === ""
      ) {
        this.items.errorschoolName = "Nama Sekolah Wajib Diisi";
        this.items.errorprovince = "Provinsi Wajib Diisi";
        this.items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        this.items.errorregency = "Kecamatan Wajib Diisi";
        this.items.errordetail = "Alamat Wajib Diisi";
        this.toast.error("Initial Tidak Boleh Kosong!");
      } else {
        var cekvalidall = true;
        if (this.editSekolah.email) {
          var pattern =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
          var text = this.editSekolah.email;

          if (text.match(pattern)) {
            this.items.erroremail = "";
            cekvalidall = true;
          } else {
            this.items.erroremail = "Email Harus Valid";
            cekvalidall = false;
          }
        }
        if (cekvalidall == true) {
          this.isLoading = true;

          if (this.editSekolah.bankAreaId == "") {
            this.editSekolah.bankAreaId = null
          }
          if (this.editSekolah.bankBranchId == "") {
            this.editSekolah.bankBranchId = null
          }
          let query = {
            name: this.editSekolah.name,
            phone: this.editSekolah.phone,
            email: this.editSekolah.email,
            initial: this.editSekolah.initial,
            fundingName: this.editSekolah.fundingName,
            picName: this.editSekolah.picName,
            picPhone: this.editSekolah.picPhone,
            picEmail: this.editSekolah.picEmail,
            schoolType: this.editSekolah.schoolType,
            picProduct: this.editSekolah.picProduct,
            status: this.editSekolah.status,
            targetLive: this.editSekolah.targetLive,
            customApp: this.editSekolah.customApp,
            address: {
              province: this.editSekolah.address.province,
              city: this.editSekolah.address.city,
              regency: this.editSekolah.address.regency,
              detail: this.editSekolah.address.detail,
            },
            bankAreaId: this.editSekolah.bankAreaId,
            bankBranchId: this.editSekolah.bankBranchId,
            bankData: this.editSekolah.bankData,
            implementatorData: this.editSekolah.implementatorData,
            gps: this.editSekolah.gps,
            npwp: this.editSekolah.npwp,
            referenceFrom: this.editSekolah.referenceFrom,
            note: this.editSekolah.note,
            salesArea: this.editSekolah.salesArea,
            topupFee: this.editSekolah.topupFee,
            automaticPayment: this.editSekolah.automaticPayment,
            blockCardTransaction: this.editSekolah.blockCardTransaction,
            faceRecognition: this.editSekolah.faceRecognition,
          }
          // this.submitButton?.value?.setAttribute("data-kt-indicator", "on");
          // if (items.role == "SALES_AREA" || items.role == "SALES_LEADER" ){

          //   if (editSekolah.salesArea == null || editSekolah.salesArea == ""){
          //     return toast.error("Sales Sekolah Tidak Boleh Kosong !")
          //   }
          // }
          ApiService.putWithData(
            "crmv2/main_school/school/school/" + this.route.params._id,
            query
          )
            .then((res) => {
              this.toast.success("Berhasil Ubah Sekolah");
              this.router.push({ path: "/superadmin/sekolah" });
              this.isLoading = false;
            })
            .catch((e) => {
              if (e.response.status == 401) {
                this.toast.error(e.response.data.detail);
                this.router.push("/sign-in");
                this.isLoading = false;
              } else {
                this.toast.error(e.response.data.detail);
                this.isLoading = false;
              }
            });

          // submitButton.value?.removeAttribute("data-kt-indicator");
        }
      }
    },

    saveNpwp() {
      let formData = new FormData();
      formData.append("npwpFile", this.tambahNpwp.file);
      console.log(formData);

      ApiService.postWithData(
        "/crmv2/main_school/school/school/upload_npwp?schoolId=" +
        this.route.params._id,
        formData
      )
        .then((res) => {
          this.editSekolah.npwp.imageUrl = res.data.npwp.imageUrl;
          console.log(res.data, "data");
          this.saveChanges1();
        })
        .catch((e) => {
          this.toast.error(e.response.data.detail);
          this.isLoading = false;
        });
    },

    getIdSekolah() {
      this.isLoading = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/" + this.route.params._id
      )
        .then((res) => {
          this.editSekolah = res.data

          this.items.url =
            ApiService.vueInstance.axios.defaults.baseURL +
            "crmv2/main_school/school/school/download_npwp_by_file?schoolId=" +
            this.route.params._id +
            "&imgFile=" +
            res.data.npwp.imageUrl;


          this.getComboUser();
          // this.getTempProv(res.data.address.province, res.data.address.city, res.data.address.regency)
          this.getProvinsi(res.data.address.province, res.data.address.city, res.data.address.regency)
          this.getListArea(this.editSekolah.bankData[0])
          this.getListBranch(this.editSekolah.bankData[0], this.editSekolah.bankAreaId)
          console.log(res.data.address.province, res.data.address.city, res.data.address.regency);



          this.isLoading = false;
        })
        .catch((e) => {
          this.toast.error(e.response.data.detail);

          this.isLoading = false;
        });
    },

    changeBank(e) {
      console.log(e)
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          this.items.listBank = response.data;
          if (this.editSekolah.bankData.length < 1) {

            this.editSekolah.bankAreaId = null
            this.editSekolah.bankBranchId = null
          }
        }
      ).catch(e => {

      });
    },
    getListBank() {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/combo_banks").then(
        (response) => {
          this.items.listBank = response.data;
          this.editSekolah.bankAreaId = null
        }
      ).catch(e => {
        this.items.listBank = []
      });
    },
    changeListArea(bankId) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/combo_areas").then(
        (response) => {
          this.items.listArea = response.data;
          this.items.listBranch = []
          this.editSekolah.bankBranchId = null

        }
      ).catch(e => {
        this.items.listArea = []
        this.editSekolah.bankAreaId = null
      });
    },
    getListArea(bankId) {
      console.log(bankId)
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/combo_areas").then(
        (response) => {
          this.items.listArea = response.data;

        }
      ).catch(e => {
        this.items.listArea = []
        this.editSekolah.bankAreaId = null
      });
    },
    changeListBranch(bankId, areaId) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/" + areaId + "/combo_branchs").then(
        (response) => {
          console.log(response.data, "cdf")
          this.editSekolah.bankBranchId = null
          this.items.listBranch = response.data;
        }
      ).catch(e => {
        this.editSekolah.bankBranchId = null
        this.items.listBranch = []
      });
    },
    getListBranch(bankId, areaId) {
      ApiService.getWithoutSlug("crmv2/main_bank/bank/office/" + bankId + "/" + areaId + "/combo_branchs").then(
        (response) => {
          console.log(response.data, "cdf")
          this.items.listBranch = response.data;
        }
      ).catch(e => {
        this.editSekolah.bankBranchId = null
        this.items.listBranch = []
      });
    },

    getListSalesArea() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/combo/school/get_combo_sales_area"
      ).then((response) => {
        this.items.ListSalesArea = response.data;
      });
    },

    getListFunding() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/combo/school/get_combo_bank_funding"
      ).then((response) => {
        this.items.listFunding = response.data;
      });
    },

    pilihProv(event, name) {

      if (event) {
        this.editSekolah.address.province = name;
        this.editSekolah.address.city = "";
        this.editSekolah.address.regency = "";
        this.items.kecamatanOptions = [];
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + event
        ).then((response) => {
          this.items.kabupatenOptions = response.data;

          console.log("ass");

          // this.getComboUser();

        });
      } else {
        this.editSekolah.address.province = "";
        this.editSekolah.address.city = "";
        this.editSekolah.address.regency = "";
        this.items.errorprovince = "Provinsi Wajib Diisi";
        // this.getKabupaten();
        this.items.kabupatenOptions = [];
        this.items.kecamatanOptions = [];
      }

    },

    pilihKab(event, name) {


      if (event) {
        this.editSekolah.address.city = name;
        this.editSekolah.address.regency = "";
        this.getKecamatan(event);
      } else {
        this.editSekolah.address.city = "";
        this.editSekolah.address.regency = "";
        this.items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        this.getKecamatan(event);
        this.items.kecamatanOptions = [];
      }

    },

    pilihKec(event) {

      console.log(event);

      if (event) {
        this.editSekolah.address.regency = event;
        // getKecamatan();
      } else {
        this.editSekolah.address.regency = "";
        this.items.errorregency = "Kecamatan Wajib Diisi";
        // getKecamatan();
      }
    },

    getProvinsi(e, city, regency) {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        this.items.provinsiOptions = response.data;

        let indexProv = this.items.provinsiOptions.findIndex(
          (x) => x.name === e
        );
        this.editSekolah.address.province = response.data[indexProv].name
        console.log(this.editSekolah.address.province, "prov");

        // ApiService.getWithoutSlug(
        //   "https://api.katalis.info/wilayah/getAllProvinces"
        // ).then((response) => {
        //   localStorage.setItem(
        //     "idProvinsi",
        //     JSON.stringify(response.data[indexProv].id)
        //   );

        this.getTempKab(response.data[indexProv].id, city, regency);
      });
    },

    getKabupaten(e) {
      var indexProv = this.items.provinsiOptions.findIndex(
        (x) => x.name === this.editSekolah.address.province
      );

      if (indexProv != null) {
        this.isLoading = true;

        var provId = this.items.provinsiOptions[indexProv].id;

      }
    },

    getKecamatan(e) {
      var indexKab = this.items.kabupatenOptions.findIndex(
        (x) => x.name === this.editSekolah.address.city
      );

      if (indexKab != null) {
        var kabupatenId = this.items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + e
        ).then((response) => {
          this.items.kecamatanOptions = response.data;
        });
      }
    },

    getTempProv(e, city, regency) {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        this.items.provinsiOptions = response.data;

        let indexProv = this.items.provinsiOptions.findIndex(
          (x) => x.name === e
        );
        this.editSekolah.address.province = response.data[indexProv].name
        console.log(this.editSekolah.address.province, "prov");

        // ApiService.getWithoutSlug(
        //   "https://api.katalis.info/wilayah/getAllProvinces"
        // ).then((response) => {
        //   localStorage.setItem(
        //     "idProvinsi",
        //     JSON.stringify(response.data[indexProv].id)
        //   );

        this.getTempKab(response.data[indexProv].id, city, regency);
        // });
      });
    },

    getTempKab(e, name, regency) {
      console.log("jsdhjh", e, name);

      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getRegencies/" +
        e
      ).then((response) => {
        this.items.kabupatenOptions = response.data;

        let indexKab = { name: "", id: "" }
        this.items.kabupatenOptions.forEach(element => {
          if (element.name === name) {
            indexKab = element

          }
        });
        console.log(indexKab, "city");
        this.editSekolah.address.city = indexKab.name
        // ApiService.getWithoutSlug(
        //   "https://api.katalis.info/wilayah/getRegencies/" +
        //   JSON.parse(localStorage.getItem("idProvinsi")!)
        // ).then((response) => {
        //   localStorage.setItem(
        //     "idKab",
        //     JSON.stringify(response.data[indexKab].id)
        //   );

        this.getTempKec(indexKab.id, regency);
        // });
      }).catch(err => {
        console.log(err, "error");

      });
    },

    getTempKec(e, name) {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getDistricts/" +
        e
      ).then((response) => {
        this.items.kecamatanOptions = response.data;
        console.log(response);


        this.editSekolah.address.regency = name
        // editSekolah.address.regency = response.data[indexKec].name
      });
    },

    combo_user_sales_area() {
      ApiService.getWithoutSlug(
        "crmv2/main_user/user/combo_user?userRole=SALES_AREA"
      ).then((response) => {
        this.items.user_sales_area = response.data;
      });
    },

    combo_user_implementator() {
      ApiService.getWithoutSlug(
        "crmv2/main_user/implementator/combo_user"
      ).then((response) => {
        this.items.user_implementator = response.data;
      });
    },

    getComboUser() {
      if (this.editSekolah.address.province) {
        this.isLoading = true;

        ApiService.getWithoutSlug(
          "crmv2/main_user/sales/team/get_combo_user?area=" +
          this.editSekolah.address.province
        )
          .then((response) => {
            this.items.user_sales_area = response.data;

            this.isLoading = false;
          })
          .catch((e) => {
            if (e.response.status == 401) {
              this.toast.error(e.response.data.detail);
              this.router.push("/sign-in");
            } else {
              this.toast.error(e.response.data.detail);
            }

            this.isLoading = false;
          });
      } else {
        this.isLoading = true;

        ApiService.getWithoutSlug("crmv2/main_user/sales/team/get_combo_user")
          .then((response) => {
            this.items.user_sales_area = response.data;

            this.isLoading = false;
          })
          .catch((e) => {
            if (e.response.status == 401) {
              this.toast.error(e.response.data.detail);
              this.router.push("/sign-in");
            } else {
              this.toast.error(e.response.data.detail);
            }

            this.isLoading = false;
          });
      }
    },

  },

});
