
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

interface data {
  tableDataPresentasi: any;
}

interface tambahPresentasiA {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  respon: string;
  participant: string;
  note: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  errorprocessed: string;
  erroractorName: string;
  errorrespon: string;
  errorparticipant: string;
  aksi: string;
  idProgress: string;
}

export default defineComponent({
  name: "Progress Presentasi Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0;

    const data = reactive<data>({
      tableDataPresentasi: [],
    });

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      errorprocessed: "",
      erroractorName: "",
      errorrespon: "",
      errorparticipant: "",
      aksi: "",
      idProgress: "",
    });

    const tambahPresentasi = reactive<tambahPresentasiA>({
      progressType: "Presentation",
      progressDate: "",
      processed: "",
      actorName: "",
      respon: "",
      participant: "",
      note: "",
    });

    const getProgress = () => {
      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          data.tableDataPresentasi = res.data.progress.presentation.detail;
          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const submitAdd = () => {
      if (
        tambahPresentasi.progressDate === "" ||
        tambahPresentasi.processed === "" ||
        tambahPresentasi.actorName === "" ||
        tambahPresentasi.respon === "" ||
        tambahPresentasi.participant === ""
      ) {
        items.errorprogressDate = "Tanggal Wajib Diisi";
        items.errorprocessed = "Proses Wajib Diisi";
        items.erroractorName = "Pengisi Presentasi Wajib Diisi";
        items.errorrespon = "Respon Wajib Diisi";
        items.errorparticipant = "Peserta Wajib Diisi";
      } else {
        items.errorprogressDate = "";
        items.errorprocessed = "";
        items.erroractorName = "";
        items.errorrespon = "";
        items.errorparticipant = "";

        // jika tombol tambah
        if (items.aksi == "tambah") {
          isLoading.value = true;
          // Activate loading indicator
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/progress/" +
              route.params._id +
              "?progressType=" +
              tambahPresentasi.progressType +
              "&actorName=" +
              tambahPresentasi.actorName +
              "&progressDate=" +
              tambahPresentasi.progressDate +
              "&processed=" +
              tambahPresentasi.processed +
              "&participant=" +
              tambahPresentasi.participant +
              "&note=" +
              tambahPresentasi.note +
              "&respon=" +
              tambahPresentasi.respon
          )

            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Tambah Presentasi");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahPresentasi.progressDate = "";
              tambahPresentasi.processed = "";
              tambahPresentasi.actorName = "";
              tambahPresentasi.respon = "";
              tambahPresentasi.participant = "";
              tambahPresentasi.note = "";

              isLoading.value = false;
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });
        } else {
          isLoading.value = true;

          // jika tombol edit progress

          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request

          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/update_presentation_detail/" +
              route.params._id +
              "/" +
              items.idProgress +
              "?progressDate=" +
              tambahPresentasi.progressDate +
              "&processed=" +
              tambahPresentasi.processed +
              "&note=" +
              tambahPresentasi.note +
              "&actorName=" +
              tambahPresentasi.actorName +
              "&respon=" +
              tambahPresentasi.respon +
              "&participant=" +
              tambahPresentasi.participant
          )
            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Update Presentasi");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahPresentasi.progressDate = "";
              tambahPresentasi.processed = "";
              tambahPresentasi.actorName = "";
              tambahPresentasi.respon = "";
              tambahPresentasi.participant = "";
              tambahPresentasi.note = "";

              isLoading.value = false;
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });
        }

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const getId = (index, item) => {
      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_presentation_webinar_detail/" +
          route.params._id +
          "/" +
          item.id
      )
        .then((res) => {
          tambahPresentasi.progressDate = res.data.progressDate;
          tambahPresentasi.processed = res.data.processed;
          tambahPresentasi.actorName = res.data.actorName;
          tambahPresentasi.respon = res.data.respon;
          tambahPresentasi.participant = res.data.participant;
          tambahPresentasi.note = res.data.note;

          items.idProgress = res.data.progressId;
          items.aksi = "ubah";

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);

          isLoading.value = false;
        });
    };

    const tambahV = () => {
      items.aksi = "tambah";
    };

    const handleDelete = (index, item) => {
      Swal.fire({
        title: "Apakah Anda Yakin Hapus Presentasi ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          deleteRow(item);
        }
      });
    };

    const deleteRow = (item) => {
      isLoading.value = true;
      const toast = useToast();

      ApiService.delete(
        "crmv2/main_school/school/school/delete_presentation_detail/" +
          route.params._id +
          "/" +
          item.id +
          "?progress=Presentation"
      )
        .then((res) => {
          toast.success("Hapus Progress Presentasi Berhasil");
          getProgress();
          isLoading.value = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus Presentasi");
          isLoading.value = false;
        });
    };

    const kosongkanVariabel = () => {
      tambahPresentasi.progressDate = "";
      tambahPresentasi.processed = "";
      tambahPresentasi.actorName = "";
      tambahPresentasi.respon = "";
      tambahPresentasi.participant = "";
      tambahPresentasi.note = "";

      items.errorprogressDate = "";
      items.errorprocessed = "";
      items.erroractorName = "";
      items.errorrespon = "";
      items.errorparticipant = "";
    };

    onMounted(() => {
      getProgress();
    });

    return {
      submitButton,
      data,
      getProgress,
      submitAdd,
      items,
      tambahPresentasi,
      uploadModalRef,
      handleDelete,
      deleteRow,
      getId,
      kosongkanVariabel,
      tambahV,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
