
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface updateImplementasiA {
  progressType: string;
  progressDate: string;
  processed: string;
  actorName: string;
  note: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  errorprocessed: string;
  erroractorName: string;
  idProgress: string;
}

export default defineComponent({
  name: "Progress Implementasi Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0;

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      errorprocessed: "",
      erroractorName: "",
      idProgress: "",
    });

    const updateImplementasi = reactive<updateImplementasiA>({
      progressType: "Implementasi",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const tampilImplementasi = reactive<updateImplementasiA>({
      progressType: "Implementasi",
      progressDate: "",
      processed: "",
      actorName: "",
      note: "",
    });

    const getProgress = () => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          // tampil adalah yg ditampilkan di tabel
          tampilImplementasi.progressDate = res.data.progress.implementasi.progressDate; 
          tampilImplementasi.processed = res.data.progress.implementasi.processed;
          tampilImplementasi.actorName = res.data.progress.implementasi.actorName;
          tampilImplementasi.note = res.data.progress.implementasi.note;

          // update adalah yg ditampilkan di modal form update
          updateImplementasi.progressDate = res.data.progress.implementasi.progressDate; 
          updateImplementasi.processed = res.data.progress.implementasi.processed;
          updateImplementasi.actorName = res.data.progress.implementasi.actorName;
          updateImplementasi.note = res.data.progress.implementasi.note;

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const kosongkanVariabel = () => {
      updateImplementasi.progressDate = tampilImplementasi.progressDate;
      updateImplementasi.processed = tampilImplementasi.processed;
      updateImplementasi.actorName = tampilImplementasi.actorName;
      updateImplementasi.note = tampilImplementasi.note;

      // kosongkan variable error message
      items.errorprogressDate = "";
      items.errorprocessed = "";
      items.erroractorName = "";

    };

    const submitAdd = () => {
      if (
        updateImplementasi.progressDate === null ||
        updateImplementasi.progressDate === "" ||
        updateImplementasi.processed === null ||
        updateImplementasi.processed === "" ||
        updateImplementasi.actorName === null || 
        updateImplementasi.actorName === "" 
      ) {
        items.errorprogressDate = "Tanggal Wajib Diisi";
        items.errorprocessed = "Proses Wajib Diisi";
        items.erroractorName = "Penanggung Jawab  Wajib Diisi";
      } else {
        items.errorprogressDate = "";
        items.errorprocessed = "";
        items.erroractorName = "";

        isLoading.value = true;

          // Activate loading indicator
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          // Send login request
          ApiService.PutMethodWithoutData(
            "crmv2/main_school/school/school/progress/" +
              route.params._id +
              "?progressType=" +
              updateImplementasi.progressType +
              "&actorName=" +
              updateImplementasi.actorName +
              "&progressDate=" +
              updateImplementasi.progressDate +
              "&processed=" +
              updateImplementasi.processed +
              "&note=" +
              updateImplementasi.note
          )

            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Update Implementasi");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              updateImplementasi.progressDate = ""; 
              updateImplementasi.processed = "";
              updateImplementasi.actorName = "";
              updateImplementasi.note = "";

              isLoading.value = false;
              
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    onMounted(() => {
      getProgress();
    });

    return {
      submitButton,
      getProgress,
      submitAdd,
      items,
      updateImplementasi,
      uploadModalRef,
      tampilImplementasi,
      kosongkanVariabel,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
