
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { left } from "@popperjs/core";

interface data {
  tableDataCollect: any;
}

interface tambahDataCollectA {
  // progressType: string;
  progressDate: string;
  processed: string;
  creatorName: string;
  actorName: string;
  note: string;
  file: string;
}

interface items {
  disableButton: boolean;
  errorprogressDate: string;
  errorprocessed: string;
  erroractorName: string;
  errorfile: string;
  aksi: string;
  idProgress: string;
  fileInputKey: any;
  creatorID: string;
  dataCollect: any;
  file: string;
}

export default defineComponent({
  name: "Progress Pengumpulan Data",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Modal,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const uploadModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0;

    const data = reactive<data>({
      tableDataCollect: [],
    });

    let items = reactive<items>({
      disableButton: false,
      errorprogressDate: "",
      errorprocessed: "",
      erroractorName: "",
      errorfile: "",
      aksi: "tambah",
      idProgress: "",
      fileInputKey: 0,
      dataCollect: "",
      creatorID: "",
      file: "",
    });

    const tambahDataCollect = reactive<tambahDataCollectA>({
      progressDate: "",
      processed: "false",
      creatorName: "",
      actorName: "",
      note: "",
      file: "",
    });

    const handleFileUpload = (event) => {
      const val = event.target.files[0].name;
      const myArray = val.split(".");

      if (myArray[1] == "xlsx" || myArray[1] == "xls") {
        tambahDataCollect.file = event.target.files[0];
        items.file = event.target.files[0];
      } else {
        tambahDataCollect.file = "";
        items.fileInputKey++;

        toast.error("Ekstensi Dokumen Tidak Sesuai (xls/xlsx)");
      }
    };

    const getProgress = () => {
      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_progress/" + route.params._id
      )
        .then((res) => {
          data.tableDataCollect = res.data.progress.dataCollect.detail;
          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const submitAdd = () => {
      console.log("cek = " + items.aksi);

      // jika tombol tambah
      if (items.aksi == "tambah") {
        if (
          tambahDataCollect.progressDate === null ||
          tambahDataCollect.progressDate === "" ||
          tambahDataCollect.actorName === null ||
          tambahDataCollect.actorName === "" ||
          tambahDataCollect.file === null ||
          tambahDataCollect.file === ""
        ) {
          items.errorprogressDate = "Tanggal Wajib Diisi";
          items.erroractorName = "Penanggung Jawab  Wajib Diisi";
          items.errorfile = "File Upload Wajib Diisi";
        } else {
          items.errorprogressDate = "";
          items.erroractorName = "";
          items.errorfile = "";

          isLoading.value = true;
          // Activate loading indicator
          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          let formData = new FormData();

          formData.append("dataCollectFile", tambahDataCollect.file);
          // Send login request

          ApiService.putWithData(
            "crmv2/main_school/school/school/progress/" +
              route.params._id +
              "?progressType=Pengumpulan%20Data" +
              "&actorName=" +
              tambahDataCollect.actorName +
              "&progressDate=" +
              tambahDataCollect.progressDate +
              "&processed=true&note=" +
              tambahDataCollect.note,
            formData
          )

            .then((res) => {
              hideModal(uploadModalRef.value);
              toast.success("Berhasil Tambah Pengumpulan Data");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              // tambahDataCollect.progressType = "Pengumpulan Data";
              tambahDataCollect.progressDate = "";
              tambahDataCollect.processed = "";
              tambahDataCollect.actorName = "";
              tambahDataCollect.note = "";

              tambahDataCollect.file = "";
              items.fileInputKey++;

              items.aksi = "tambah";

              isLoading.value = false;
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });

          submitButton.value?.removeAttribute("data-kt-indicator");
        }
      } else {
        if (
          tambahDataCollect.progressDate === null ||
          tambahDataCollect.progressDate === "" ||
          tambahDataCollect.actorName === null ||
          tambahDataCollect.actorName === ""
        ) {
          items.erroractorName = "Penanggung Jawab  Wajib Diisi";
          items.errorprogressDate = "Tanggal Wajib Diisi";
        } else {
          items.errorprogressDate = "";
          items.erroractorName = "";
          items.errorfile = "";

          isLoading.value = true;

          // jika tombol edit progress

          items.disableButton = true;
          submitButton.value?.setAttribute("data-kt-indicator", "on");

          let formData = new FormData();

          formData.append("dataCollectFile", tambahDataCollect.file);

          // Send login request

          ApiService.putWithData(
            "crmv2/main_school/school/school/update_data_collect_detail/" +
              route.params._id +
              "/" +
              items.idProgress +
              "?progressDate=" +
              tambahDataCollect.progressDate +
              "&actorName=" +
              tambahDataCollect.actorName +
              "&note=" +
              tambahDataCollect.note,
            formData
          )
            .then((res) => {
              hideModal(uploadModalRef.value);
              items.aksi = "tambah";
              toast.success("Berhasil Ubah Pengumpulan Data");
              submitButton.value?.removeAttribute("data-kt-indicator");
              getProgress();

              items.disableButton = false;

              tambahDataCollect.progressDate = "";
              tambahDataCollect.actorName = "";
              tambahDataCollect.note = "";

              tambahDataCollect.file = "";
              items.fileInputKey++;
              items.file = "";

              isLoading.value = false;
            })
            .catch((e) => {
              items.disableButton = false;
              hideModal(uploadModalRef.value);
              submitButton.value?.removeAttribute("data-kt-indicator");
              toast.error(e.response.data.detail);

              isLoading.value = false;
            });

          submitButton.value?.removeAttribute("data-kt-indicator");
        }
      }
    };

    const getId = (item) => {
      items.aksi = "ubah";

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/get_presentation_webinar_detail/" +
          route.params._id +
          "/" +
          item.id
      )
        .then((res) => {
          // tambahDataCollect.progressType = res.data.progressType;
          tambahDataCollect.progressDate = res.data.progressDate;
          tambahDataCollect.processed = res.data.processed;
          tambahDataCollect.actorName = res.data.actorName;
          tambahDataCollect.note = res.data.note;

          items.file = res.data.fileName;
          items.fileInputKey++;

          items.idProgress = res.data.progressId;

          const modal = new Modal(
            document.getElementById("kt_modal_datacollect")
          );
          modal.show();

          items.idProgress = item.id;

          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);

          isLoading.value = false;
        });
    };

    const handleDelete = (index, item) => {
      Swal.fire({
        title: "Apakah Anda Yakin Hapus Pengumpulan Data ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          deleteRow(item);
        }
      });
    };

    const deleteRow = (item) => {
      isLoading.value = true;
      const toast = useToast();

      ApiService.delete(
        "crmv2/main_school/school/school/delete_presentation_detail/" +
          route.params._id +
          "/" +
          item.id +
          "?progress=Pengumpulan Data"
      )
        .then((res) => {
          toast.success("Hapus Progress Pengumpulan Data Berhasil");
          getProgress();
          isLoading.value = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus Pengumpulan Data");
          isLoading.value = false;
        });
    };

    const kosongkanVariabel = () => {
      items.aksi = "tambah";

      tambahDataCollect.progressDate = "";
      tambahDataCollect.processed = "";
      tambahDataCollect.actorName = "";
      tambahDataCollect.note = "";
      tambahDataCollect.file = "";

      items.fileInputKey++;
      items.errorprogressDate = "";
      items.errorprocessed = "";
      items.erroractorName = "";
      items.file = "";
    };

    const downloadDL = () => {
      window.open(
        "https://api.teknologikartu.com/crmv2/main_school/school/school/download_school/data_collect/" +
          route.params._id +
          "/" +
          items.idProgress,
        "_blank"
      );
    };

    onMounted(() => {
      getProgress();
      items.creatorID = JSON.parse(localStorage.getItem("user_account")!).name;
    });

    return {
      submitButton,
      data,
      downloadDL,
      getProgress,
      submitAdd,
      items,
      tambahDataCollect,
      uploadModalRef,
      handleDelete,
      handleFileUpload,
      deleteRow,
      getId,
      kosongkanVariabel,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
