
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import TableProgressWebinar from "@/components/1crm/sekolah/table-progress-webinar.vue";
import TableProgressPresentasi from "@/components/1crm/sekolah/table-progress-presentasi.vue";
import TableProgressProposal from "@/components/1crm/sekolah/table-progress-proposal.vue";
import TableProgressFollowUp from "@/components/1crm/sekolah/table-progress-follow-up.vue";
import TableProgressPKS from "@/components/1crm/sekolah/table-progress-pks.vue";
import TableProgressDataCollect from "@/components/1crm/sekolah/table-progress-data-collect.vue";
import TableProgressPreImplementasi from "@/components/1crm/sekolah/table-progress-pre-implementasi.vue";
import TableProgressTagihan from "@/components/1crm/sekolah/table-progress-tagihan.vue";
import TableProgressImplementasi from "@/components/1crm/sekolah/table-progress-implementasi.vue";
import TableProgressAfterSales from "@/components/1crm/sekolah/table-progress-after-sales.vue";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,  
    ErrorMessage,
    Field,
    Form,
    TableProgressWebinar,
    TableProgressPresentasi,
    TableProgressProposal,
    TableProgressFollowUp,
    TableProgressPKS,
    TableProgressDataCollect,
    TableProgressPreImplementasi,
    TableProgressTagihan,
    TableProgressImplementasi,
    TableProgressAfterSales,
    Loading, 
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
    });

    return {
      submitButton,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
