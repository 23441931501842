
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import EditSchool from "@/components/1crm/sekolah/edit-sekolah.vue";
import ProgressSchool from "@/components/1crm/sekolah/edit-progress-sekolah.vue";
import DokumenPks from "@/components/1crm/sekolah/dokumen-sekolah.vue";
import Penggunaanfitur from "@/components/1crm/sekolah/penggunaanfitur.vue";
import KonfigSchool from "@/components/1crm/sekolah/konfig-sekolah.vue";

interface items {
  infoSekolah: Boolean;
  progress: Boolean;
  konfigsekolah: Boolean;
  DokumenPks: Boolean;
  Penggunaanfitur: Boolean;
  role: any;
}

export default defineComponent({
  name: "Edit Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    EditSchool,
    ProgressSchool,
    DokumenPks,
    KonfigSchool,
    Penggunaanfitur,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let items = reactive<items>({
      infoSekolah: true,
      progress: false,
      konfigsekolah: false,
      DokumenPks: false,
      Penggunaanfitur: false,
      role: "",
    });

    const infoSekolah = () => {
      items.infoSekolah = true;
      items.progress = false;
      items.konfigsekolah = false;
      items.DokumenPks = false;
      items.Penggunaanfitur = false;
    };

    const progress = () => {
      items.progress = true;
      items.infoSekolah = false;
      items.konfigsekolah = false;
      items.DokumenPks = false;
      items.Penggunaanfitur = false;
    };

    const konfigsekolah = () => {
      items.progress = false;
      items.infoSekolah = false;
      items.konfigsekolah = true;
      items.DokumenPks= false;
      items.Penggunaanfitur = false;
    };
    const DokumenPks = () => {
      items.progress = false;
      items.infoSekolah = false;
      items.konfigsekolah = false;
      items.DokumenPks = true;
      items.Penggunaanfitur = false;
    };
    const Penggunaanfitur = () => {
      items.progress = false;
      items.infoSekolah = false;
      items.konfigsekolah = false;
      items.DokumenPks = false;
      items.Penggunaanfitur = true;
    };


    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      setCurrentPageBreadcrumbs("Ubah Data Sekolah", ["Data"]);
      items.role = JSON.parse(localStorage.getItem("user_account")!).role;
      if(items.role === 'ADMIN_CARD'){

        items.progress = false;
      items.infoSekolah = false;
      items.DokumenPks = false;
      items.Penggunaanfitur = false;
      items.konfigsekolah = true;
      }
      
    });

    return {
      submitButton,
      infoSekolah,
      items,
      progress,
      DokumenPks,
      konfigsekolah,
      Penggunaanfitur,
      
    };
  },
});
